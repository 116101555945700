/* login page styles set */
/* CORE: fonts */
@font-face {
  font-family: 'ProximaNova';
  src: url('/public/fonts/ProximaNova-RegularWeb.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('/public/fonts/ProximaNova-RegularItWeb.woff') format('woff');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('/public/fonts/ProximaNova-LightWeb.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('/public/fonts/ProximaNova-SemiboldWeb.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('/public/fonts/ProximaNova-SemiboldItWeb.woff') format('woff');
  font-style: italic;
  font-weight: bold;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('/public/fonts/ProximaNova-BoldWeb.woff') format('woff');
  font-style: normal;
  font-weight: 800;
}
/* CORE: global vars */
/* colors */
/* font sizes */
/* line height sizes */
/* others */
/* zIndex group */
/* CORE: global styles */
/* CORE: mixins set */
/* border radious */
/* uxpin icon font settings */
/* better text underline */
/* grow dynamic animation */
/* grow dynamic animation for cloud */
/* show animate */
/* global show / hide animation */
/* box clearing stuff */
/* spinner */
/* background pattern for transparent colors */
/* select arrow */
/* collapsed / expanded arrow */
/* select break line */
/* centering element using flex */
/* sprite */
/* firefox only */
/* horizontal lines on both sides of the text */
@keyframes pinPulse {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
@keyframes border-pulse-mode {
  0%,
  100% {
    border: 5px solid rgba(0, 0, 0, 0.05);
  }
  50% {
    border: 5px solid rgba(0, 0, 0, 0.15);
  }
}
@keyframes blink-opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes max-width {
  100% {
    max-width: 100%;
  }
}
@keyframes animate-hidden {
  100% {
    opacity: 1;
    visibility: visible;
    display: block;
  }
}
@keyframes animate-visible {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
/* some merge libraries overrides global styles and our UI is broken - it's workaround */
[class*="ds-icon--font__"]:before {
  text-align: center;
  font-family: 'uxpds-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class*="ds-icon--custom__"]:before {
  font-family: "ProximaNova", sans-serif;
}
.ds-icon--font__action-add-to-favorites:before {
  content: '\E000';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-archive:before {
  content: '\E001';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-unarchive:before {
  content: '\E056';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-arrange-left:before {
  content: '\E003';
  color: #919191;
  font-size: 14px;
  transform: rotate(-90deg);
}
.ds-icon--font__action-arrange-right:before {
  content: '\E003';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-arrange-top:before {
  content: '\E003';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-arrange-bottom:before {
  content: '\E003';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__action-arrange-center-vertical:before {
  content: '\E002';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-arrange-center-horizontal:before {
  content: '\E002';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-attach-to-side-right:before {
  content: '\E004';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-attach-to-side-left:before {
  content: '\E004';
  color: #919191;
  font-size: 14px;
  transform: scale(-1, 1);
}
.ds-icon--font__action-autoresize:before {
  content: '\E005';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-break-symbol:before {
  content: '\E006';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-change-project-group:before {
  content: '\E007';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-close:before {
  content: '\E009';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-close-bold:before {
  content: '\E009';
  color: #919191;
  font-size: 14px;
  font-weight: bold;
}
.ds-icon--font__action-close-panel:before {
  content: '\E008';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-detaching-handler:before {
  content: '\E010';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-direction-horizontal:before {
  content: '\E011';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-direction-vertical:before {
  content: '\E012';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-disable:before {
  content: '\E013';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-distribute-horizontal:before {
  content: '\E015';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-distribute-on-grid:before {
  content: '\E014';
  color: #919191;
  font-size: 14px;
  transform: translateX(0.5px);
}
.ds-icon--font__action-distribute-vertical:before {
  content: '\E015';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-duplicate:before {
  content: '\E016';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-element-align-bottom:before {
  content: '\E018';
  color: #919191;
  font-size: 11px;
  transform: rotate(-90deg);
}
.ds-icon--font__action-element-align-center-vertical:before {
  content: '\E017';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__action-element-align-center-horizontal:before {
  content: '\E017';
  color: #919191;
  font-size: 11px;
  transform: rotate(90deg);
}
.ds-icon--font__action-element-align-left:before {
  content: '\E018';
  color: #919191;
  font-size: 11px;
  transform: translateX(0.5px);
}
.ds-icon--font__action-element-align-right:before {
  content: '\E018';
  color: #919191;
  font-size: 11px;
  transform: rotate(180deg);
}
.ds-icon--font__action-element-align-top:before {
  content: '\E018';
  color: #919191;
  font-size: 11px;
  transform: rotate(90deg);
}
.ds-icon--font__action-element-flip-horizontal:before {
  content: '\E019';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-element-flip-vertical:before {
  content: '\E019';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-enable:before {
  content: '\E020';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-exit:before {
  content: '\E021';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-frame-add:before {
  content: '\E022';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-frame-remove:before {
  content: '\E023';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-filter:before {
  content: '\E024';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-full-screen:before {
  content: '\E025';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-resizing-auto-horizontal:before {
  content: '\E048';
  color: #919191;
  font-size: 14px;
  transform: translateX(0.5px);
}
.ds-icon--font__action-resizing-fill-horizontal:before {
  content: '\E049';
  color: #919191;
  font-size: 14px;
  transform: translateX(0.5px);
}
.ds-icon--font__action-resizing-fixed-horizontal:before {
  content: '\E050';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-resizing-auto-vertical:before {
  content: '\E048';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-resizing-fill-vertical:before {
  content: '\E049';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-resizing-fixed-vertical:before {
  content: '\E050';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__action-insert-data:before {
  content: '\E026';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-log-out:before {
  content: '\E027';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-mask:before {
  content: '\E028';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-move-behind:before {
  content: '\E029';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-move-by:before {
  content: '\E030';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-move-forward:before {
  content: '\E031';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-move-to:before {
  content: '\E034';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-move-to-back:before {
  content: '\E032';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-move-to-front:before {
  content: '\E033';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-multiproperty-lock:before {
  content: '\E035';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-multiproperty-unlock-borders:before {
  content: '\E036';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-multiproperty-unlock-corners:before {
  content: '\E037';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-next-state:before {
  content: '\E038';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-path-break:before {
  content: '\E039';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-path-default:before {
  content: '\E040';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-path-difference:before {
  content: '\E041';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-path-intersect:before {
  content: '\E042';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-path-subtract:before {
  content: '\E043';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-path-union:before {
  content: '\E044';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-pause:before {
  content: '\E045';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-previous-state:before {
  content: '\E046';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-remove-from-group:before {
  content: '\E047';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-set-content:before {
  content: '\E051';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-set-property:before {
  content: '\E052';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-set-storybook-property:before {
  content: '\E053';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-symbol-sync:before {
  content: '\E055';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__action-switch:before {
  content: '\E054';
  color: #919191;
  font-size: 8px;
}
.ds-icon--font__action-unmask:before {
  content: '\E057';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__adjust-settings:before {
  content: '\E058';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__advanced-animation:before {
  content: '\E059';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__angle:before {
  content: '\E061';
  color: #919191;
  font-size: 8px;
}
.ds-icon--font__api-request:before {
  content: '\E063';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-left:before {
  content: '\E077';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-right:before {
  content: '\E077';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__arrow-up:before {
  content: '\E077';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__arrow-down:before {
  content: '\E077';
  color: #919191;
  font-size: 14px;
  transform: rotate(-90deg);
}
.ds-icon--font__arrow-axis:before {
  content: '\E064';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-enter:before {
  content: '\E065';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-go-back:before {
  content: '\E066';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__arrow-play:before {
  content: '\E067';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-redo:before {
  content: '\E068';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-repeat:before {
  content: '\E069';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-replace:before {
  content: '\E070';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-retrieve:before {
  content: '\E071';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-rotate:before {
  content: '\E072';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__state-base:before {
  content: '\E273';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-show-vertical:before {
  content: '\E073';
  color: #919191;
  font-size: 9px;
}
.ds-icon--font__arrow-show-horizontal:before {
  content: '\E073';
  color: #919191;
  font-size: 9px;
  transform: rotate(90deg);
}
.ds-icon--font__arrow-size:before {
  content: '\E074';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-thin-left:before {
  content: '\E075';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__arrow-thin-right:before {
  content: '\E075';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__arrow-thin-up:before {
  content: '\E075';
  color: #919191;
  font-size: 14px;
  transform: rotate(-90deg);
}
.ds-icon--font__arrow-thin-down:before {
  content: '\E075';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__arrow-undo:before {
  content: '\E076';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-apple:before {
  content: '\E078';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-google-simple:before {
  content: '\E079';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-google:before {
  content: '\E080';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-npm:before {
  content: '\E081';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-react:before {
  content: '\E082';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-sketch:before {
  content: '\E083';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-slack:before {
  content: '\E084';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-storybook:before {
  content: '\E085';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-twitter:before {
  content: '\E086';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__brand-uxpin:before {
  content: '\E087';
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
}
.ds-icon--font__brand-windows:before {
  content: '\E088';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__building:before {
  content: '\E089';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__bulb:before {
  content: '\E090';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__calendar:before {
  content: '\E092';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__calendar-grey:before {
  content: '\E091';
  color: #919191;
  font-size: 16px;
}
.ds-icon--font__changelog:before {
  content: '\E093';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__chevron-down:before {
  content: '\E094';
  color: #919191;
  font-size: 7px;
}
.ds-icon--font__chevron-up:before {
  content: '\E094';
  color: #919191;
  font-size: 7px;
  transform: rotate(180deg);
}
.ds-icon--font__chevron-left:before {
  content: '\E094';
  color: #919191;
  font-size: 7px;
  transform: rotate(90deg);
}
.ds-icon--font__chevron-right:before {
  content: '\E094';
  color: #919191;
  font-size: 7px;
  transform: rotate(-90deg);
}
.ds-icon--font__clock:before {
  content: '\E095';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__code-block:before {
  content: '\E096';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__code-inline:before {
  content: '\E097';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__color-drop:before {
  content: '\E098';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__color-gradient-angle:before {
  content: '\E099';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__color-gradient-linear:before {
  content: '\E100';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__color-gradient-radial:before {
  content: '\E101';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__color-solid:before {
  content: '\E102';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__colors-dropper:before {
  content: '\E103';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__colors-schemes:before {
  content: '\E104';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__colors-swatches:before {
  content: '\E105';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__connection-connected:before {
  content: '\E106';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__connection-disconnected:before {
  content: '\E107';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__connection-locally:before {
  content: '\E108';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__corner-top-left:before {
  content: '\E109';
  color: #919191;
  font-size: 7px;
  transform: rotate(90deg);
}
.ds-icon--font__corner-top-right:before {
  content: '\E109';
  color: #919191;
  font-size: 7px;
  transform: rotate(180deg);
}
.ds-icon--font__corner-bottom-right:before {
  content: '\E109';
  color: #919191;
  font-size: 7px;
  transform: rotate(270deg);
}
.ds-icon--font__corner-bottom-left:before {
  content: '\E109';
  color: #919191;
  font-size: 7px;
}
.ds-icon--font__cursor-crosshair:before {
  content: '\E110';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__cursor-default:before {
  content: '\E111';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__cursor-pointer:before {
  content: '\E112';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__desktop-app:before {
  content: '\E113';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__dollar:before {
  content: '\E114';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__dot:before {
  content: '\E116';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__dot-grey:before {
  content: '\E115';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__dots-horizontal:before {
  content: '\E117';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__dots-vertical:before {
  content: '\E117';
  color: #919191;
  font-size: 14px;
  transform: rotate(90deg);
}
.ds-icon--font__elements-audio:before {
  content: '\E118';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-box:before {
  content: '\E121';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-box-with-label:before {
  content: '\E120';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-box-rounded:before {
  content: '\E119';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-button:before {
  content: '\E122';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-checkbox:before {
  content: '\E123';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-circle:before {
  content: '\E124';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-component-from-library:before {
  content: '\E126';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-code-component:before {
  content: '\E125';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-element:before {
  content: '\E127';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-frame:before {
  content: '\E128';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-generic:before {
  content: '\E129';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-grid:before {
  content: '\E130';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-grid-layout:before {
  content: '\E131';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-grid-baseline:before {
  content: '\E132';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-grid-square:before {
  content: '\E133';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-hotspot:before {
  content: '\E134';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-icon:before {
  content: '\E135';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-image:before {
  content: '\E136';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-input:before {
  content: '\E137';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-line:before {
  content: '\E138';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-merge-user-pattern:before {
  content: '\E141';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-merge-pattern:before {
  content: '\E140';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-merge-mui:before {
  content: '\E139';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-multi-select:before {
  content: '\E142';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-multistates:before {
  content: '\E144';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-multistates-edit:before {
  content: '\E143';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-navigation:before {
  content: '\E145';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-note:before {
  content: '\E146';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-path:before {
  content: '\E147';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-pencil:before {
  content: '\E148';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-radio:before {
  content: '\E149';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-select-list:before {
  content: '\E150';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-slice:before {
  content: '\E151';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-storybook:before {
  content: '\E152';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-symbol:before {
  content: '\E153';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-table:before {
  content: '\E154';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-text-block:before {
  content: '\E155';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-text-element:before {
  content: '\E156';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-text-experimental-element:before {
  content: '\E157';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-textarea:before {
  content: '\E158';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-triangle:before {
  content: '\E160';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-tooltip:before {
  content: '\E159';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-unsymbol:before {
  content: '\E161';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__elements-video:before {
  content: '\E162';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__endpoint-empty-circle:before {
  content: '\E163';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-empty-rectangle:before {
  content: '\E164';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-empty-rhomb:before {
  content: '\E165';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-empty-triangle:before {
  content: '\E166';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-filled-arrow:before {
  content: '\E167';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-filled-circle:before {
  content: '\E168';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-filled-rectangle:before {
  content: '\E169';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-filled-rhomb:before {
  content: '\E170';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-filled-triangle:before {
  content: '\E171';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-line:before {
  content: '\E172';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-mirrored-triangle:before {
  content: '\E173';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-none:before {
  content: '\E174';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__endpoint-opened-triangle:before {
  content: '\E175';
  color: #5e5e5e;
  font-size: 16px;
}
.ds-icon--font__exclamation-mark:before {
  content: '\E176';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__expander-collapsed:before {
  content: '\E177';
  color: #919191;
  font-size: 5px;
  transform: rotate(-90deg);
}
.ds-icon--font__expander-expanded:before {
  content: '\E177';
  color: #919191;
  font-size: 5px;
}
.ds-icon--font__external-link:before {
  content: '\E178';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__feature-accessibility:before {
  content: '\E179';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-animations:before {
  content: '\E180';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-approval:before {
  content: '\E181';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-auto-backup:before {
  content: '\E182';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-chat:before {
  content: '\E183';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-comments:before {
  content: '\E184';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-community:before {
  content: '\E185';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-discussion:before {
  content: '\E186';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__feature-uxpin-docs:before {
  content: '\E200';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-documentation:before {
  content: '\E187';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-grid:before {
  content: '\E188';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-iteration:before {
  content: '\E189';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-layers:before {
  content: '\E190';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-overview:before {
  content: '\E196';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-overview-big:before {
  content: '\E193';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-overview-medium:before {
  content: '\E194';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-overview-small:before {
  content: '\E195';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-responsive:before {
  content: '\E197';
  color: #919191;
  font-size: 10px;
}
.ds-icon--font__feature-sitemap:before {
  content: '\E198';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-live-presentation:before {
  content: '\E191';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-mobile:before {
  content: '\E192';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-usability-testing:before {
  content: '\E199';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__feature-version-control:before {
  content: '\E201';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__files-download:before {
  content: '\E021';
  color: #919191;
  font-size: 14px;
  transform: rotate(-90deg);
}
.ds-icon--font__files-download-cloud:before {
  content: '\E202';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__files-upload:before {
  content: '\E203';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__folder-closed:before {
  content: '\E205';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__folder-closed-outline:before {
  content: '\E204';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__folder-opened:before {
  content: '\E206';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__font-adobe:before {
  content: '\E207';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__font-local:before {
  content: '\E208';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__font-uploaded:before {
  content: '\E209';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__gap-horizontal:before {
  content: '\E210';
  color: #919191;
  font-size: 8px;
}
.ds-icon--font__gap-vertical:before {
  content: '\E211';
  color: #919191;
  font-size: 8px;
}
.ds-icon--font__gear:before {
  content: '\E212';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__git:before {
  content: '\E213';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__stackblitz:before {
  content: '\E270';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__ai:before {
  content: '\E060';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__tailwind:before {
  content: '\E293';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__antd:before {
  content: '\E062';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__react-bootstrap:before {
  content: '\E256';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__heart:before {
  content: '\E214';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__info-simple:before {
  content: '\E215';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__info-warning:before {
  content: '\E216';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__info-warning-bold:before {
  content: '\E216';
  color: #919191;
  font-size: 14px;
  font-weight: bold;
}
.ds-icon--font__key-arrow-all:before {
  content: '\E217';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__keyboard:before {
  content: '\E218';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__laptop:before {
  content: '\E219';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__layout-list:before {
  content: '\E220';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__layout-medium-tile:before {
  content: '\E222';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__layout-medium-tile-outline:before {
  content: '\E221';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__layout-small-tile:before {
  content: '\E223';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__libraries-design-libraries:before {
  content: '\E224';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__libraries-more:before {
  content: '\E225';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__libraries-simple:before {
  content: '\E226';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__link-chain:before {
  content: '\E228';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__link-chain-break:before {
  content: '\E227';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__list-indentation-add:before {
  content: '\E229';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__list-indentation-remove:before {
  content: '\E230';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__list-ordered:before {
  content: '\E231';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__list-unordered:before {
  content: '\E232';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__mail:before {
  content: '\E233';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__mask:before {
  content: '\E234';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__media-pause:before {
  content: '\E235';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__media-play:before {
  content: '\E236';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__media-stop:before {
  content: '\E237';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__minus-outline:before {
  content: '\E238';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__moon:before {
  content: '\E239';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__opacity:before {
  content: '\E240';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__options-view:before {
  content: '\E241';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__padlock-broken:before {
  content: '\E242';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__padlock-locked:before {
  content: '\E243';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__padlock-unlocked:before {
  content: '\E244';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__pencil:before {
  content: '\E246';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__piggy-bank:before {
  content: '\E247';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__pen-tool:before {
  content: '\E245';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__pin-map:before {
  content: '\E249';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__pin-map-solid:before {
  content: '\E248';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__pin-simple:before {
  content: '\E250';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__plugins:before {
  content: '\E251';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__plus:before {
  content: '\E253';
  color: #919191;
  font-size: 8px;
}
.ds-icon--font__plus-outline:before {
  content: '\E252';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__question-mark:before {
  content: '\E255';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__question-mark-solid:before {
  content: '\E254';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__scroll-to:before {
  content: '\E257';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__search:before {
  content: '\E258';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__settings:before {
  content: '\E259';
  color: #919191;
  font-size: 12px;
}
.ds-icon--font__shape-diamond:before {
  content: '\E260';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__shape-rhombus:before {
  content: '\E261';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__shape-star:before {
  content: '\E262';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__shape-triangle-right-angled:before {
  content: '\E263';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__shape-triangle:before {
  content: '\E264';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__share-closed:before {
  content: '\E265';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__share-opened:before {
  content: '\E266';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__shopping-cart:before {
  content: '\E267';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__sound-mute:before {
  content: '\E268';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__sound-unmute:before {
  content: '\E269';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__star-outline:before {
  content: '\E271';
  color: #63ad0e;
  font-size: 14px;
}
.ds-icon--font__star-solid:before {
  content: '\E272';
  color: #63ad0e;
  font-size: 14px;
}
.ds-icon--font__status-approved-solid:before {
  content: '\E274';
  color: #63ad0e;
  font-size: 14px;
}
.ds-icon--font__status-approved:before {
  content: '\E275';
  color: #63ad0e;
  font-size: 14px;
}
.ds-icon--font__status-paused:before {
  content: '\E276';
  color: #a6a6a6;
  font-size: 14px;
}
.ds-icon--font__status-progress:before {
  content: '\E277';
  color: #a6a6a6;
  font-size: 14px;
}
.ds-icon--font__status-rejected-solid:before {
  content: '\E278';
  color: #fb0d00;
  font-size: 14px;
}
.ds-icon--font__status-rejected:before {
  content: '\E279';
  color: #fb0d00;
  font-size: 14px;
}
.ds-icon--font__status-waiting:before {
  content: '\E280';
  color: #ffc000;
  font-size: 14px;
}
.ds-icon--font__stroke-end-butt:before {
  content: '\E281';
  color: #919191;
  font-size: 14px;
  top: 1px;
}
.ds-icon--font__stroke-end-round:before {
  content: '\E282';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__stroke-end-square:before {
  content: '\E283';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__stroke-join-bevel:before {
  content: '\E284';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__stroke-join-miter:before {
  content: '\E285';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__stroke-join-round:before {
  content: '\E286';
  color: #919191;
  font-size: 11px;
}
.ds-icon--font__stroke-point-asymmetric:before {
  content: '\E287';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__stroke-point-disconnected:before {
  content: '\E288';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__stroke-point-mirrored:before {
  content: '\E289';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__stroke-point-straight:before {
  content: '\E290';
  color: #919191;
  font-size: 13px;
}
.ds-icon--font__sun:before {
  content: '\E292';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__sun-half:before {
  content: '\E291';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__thunder:before {
  content: '\E294';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__ticker:before {
  content: '\E295';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__tooltip-left:before {
  content: '\E296';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__tooltip-right:before {
  content: '\E296';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__tooltip-top:before {
  content: '\E297';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__tooltip-bottom:before {
  content: '\E297';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__trash:before {
  content: '\E298';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__tutorial-text:before {
  content: '\E299';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-align-center:before {
  content: '\E300';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-align-justify:before {
  content: '\E301';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-align-left:before {
  content: '\E302';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-align-right:before {
  content: '\E302';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__typography-auto-height:before {
  content: '\E303';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-auto-width:before {
  content: '\E304';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-bold:before {
  content: '\E305';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-capitalize:before {
  content: '\E306';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-fixed-size:before {
  content: '\E307';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-fixed-width:before {
  content: '\E308';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-font-size:before {
  content: '\E309';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-fraction:before {
  content: '\E310';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-italic:before {
  content: '\E311';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-letter-spacing:before {
  content: '\E312';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-ligature:before {
  content: '\E313';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-line-height:before {
  content: '\E314';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-lowercase:before {
  content: '\E315';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-small-caps:before {
  content: '\E316';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-strike:before {
  content: '\E317';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-superscription:before {
  content: '\E319';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-subscription:before {
  content: '\E318';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-underline:before {
  content: '\E320';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-uppercase:before {
  content: '\E321';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-vertical-align-bottom:before {
  content: '\E323';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-vertical-align-center:before {
  content: '\E322';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__typography-vertical-align-top:before {
  content: '\E323';
  color: #919191;
  font-size: 14px;
  transform: rotate(180deg);
}
.ds-icon--font__user-add:before {
  content: '\E324';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__user-simple:before {
  content: '\E325';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__variable:before {
  content: '\E326';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__visibility-hidden:before {
  content: '\E327';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__visibility-toggle:before {
  content: '\E328';
  color: #919191;
  font-size: 14px;
}
.ds-icon--font__visibility-visible:before {
  content: '\E329';
  color: #919191;
  font-size: 14px;
}
.ds-icon--custom__element-line:before {
  content: '';
  width: 10px;
  height: 1px;
  transform: rotate(-45deg);
  background: #919191;
}
.ds-icon--custom__element-hr:before {
  content: '\2014';
  color: #919191;
  font-size: 17px;
  font-weight: bold;
}
.ds-icon--custom__key-alt:before {
  content: 'Alt';
  color: #fff;
  font-size: 12px;
  font-family: "ProximaNova", sans-serif;
}
.mac .ds-icon--custom__key-alt:before {
  content: '\2325';
  font-size: 10px;
}
.ds-icon--custom__key-cmd:before {
  content: 'Ctrl';
  color: #fff;
  font-size: 12px;
  font-family: "ProximaNova", sans-serif;
}
.mac .ds-icon--custom__key-cmd:before {
  content: '\2318';
}
.ds-icon--custom__key-shift:before {
  content: 'Shift';
  color: #fff;
  font-size: 12px;
  font-family: "ProximaNova", sans-serif;
}
.mac .ds-icon--custom__key-shift:before {
  content: '\21E7';
  font-size: 10px;
}
.ds-icon--custom__minus:before {
  content: '-';
  color: #919191;
  font-size: 14px;
}
.ds-icon--custom__typography-h1:before {
  content: 'H1';
  color: #919191;
  font-size: 14px;
}
.ds-icon--custom__typography-h2:before {
  content: 'H2';
  color: #919191;
  font-size: 14px;
}
.ds-icon--custom__typography-h3:before {
  content: 'H3';
  color: #919191;
  font-size: 14px;
}
.ds-icon--custom__typography-blockquote:before {
  content: '\0201D';
  color: #919191;
  font-size: 26px;
}
.ds-icon--size-parent:before {
  font-size: inherit;
}
.ds-icon--size-xs:before {
  font-size: 10px;
}
.ds-icon--size-s:before {
  font-size: 12px;
}
.ds-icon--size-m:before {
  font-size: 14px;
}
.ds-icon--size-l:before {
  font-size: 16px;
}
.ds-action-icon-base {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  width: 34px;
  height: 34px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: transparent;
  color: #5e5e5e;
  text-decoration: none;
  cursor: pointer;
}
.ds-action-icon-base:before,
.ds-action-icon-base:after {
  box-sizing: border-box;
}
.ds-action-icon-base:active,
.ds-action-icon-base:focus {
  outline: none;
}
.ds-action-icon-base:hover,
.ds-action-icon-base.ds-util-is-marked {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
}
.ds-action-icon-base:active,
.ds-action-icon-base.ds-util-is-active {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.ds-action-icon-base--size-s {
  width: 24px;
  height: 24px;
}
.ds-action-icon-base--size-s.ds-action-icon-base--is-working:after {
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
}
.ds-action-icon-base--size-s:before {
  font-size: 12px;
}
.ds-action-icon-base--size-xs {
  width: 18px;
  height: 18px;
}
.ds-action-icon-base--size-xs.ds-action-icon-base--is-working:after {
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-width: 1px;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--size-xs.ds-action-icon-base--is-working:after {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--size-xs.ds-action-icon-base--is-working:after {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}
.ds-action-icon-base--size-xs:before {
  font-size: 8px;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--size-xs:before {
  font-size: 10px;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--size-xs:before {
  font-size: 10px;
}
.ds-action-icon-base--stretched {
  width: 100%;
  height: 100%;
  border-radius: initial;
}
.ds-action-icon-base--style-approve {
  border-color: rgba(113, 197, 16, 0.3);
  color: #71c510;
}
.ds-action-icon-base--style-approve:hover,
.ds-action-icon-base--style-approve.ds-util-is-marked {
  border-color: rgba(113, 197, 16, 0.3);
  background-color: rgba(113, 197, 16, 0.05);
}
.ds-action-icon-base--style-approve:active,
.ds-action-icon-base--style-approve.ds-util-is-active {
  border-color: #71c510;
  background-color: #71c510;
  color: #fff;
}
.ds-action-icon-base--style-chameleon {
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.ds-action-icon-base--style-chameleon:hover,
.ds-action-icon-base--style-chameleon.ds-util-is-marked {
  background-color: rgba(255, 255, 255, 0.2);
}
.ds-action-icon-base--style-chameleon:active,
.ds-action-icon-base--style-chameleon.ds-util-is-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.ds-action-icon-base--style-destructive {
  border-color: rgba(251, 13, 0, 0.15);
  color: #fb0d00;
}
.ds-action-icon-base--style-destructive:hover,
.ds-action-icon-base--style-destructive.ds-util-is-marked {
  border-color: rgba(251, 13, 0, 0.15);
  background-color: rgba(251, 13, 0, 0.05);
}
.ds-action-icon-base--style-destructive:active,
.ds-action-icon-base--style-destructive.ds-util-is-active {
  border-color: rgba(251, 13, 0, 0.15);
  background-color: rgba(251, 13, 0, 0.1);
  color: #fb0d00;
}
.ds-action-icon-base--weight-light {
  border: none;
}
.ds-action-icon-base--weight-light:hover,
.ds-action-icon-base--weight-light.ds-util-is-marked {
  background-color: rgba(0, 0, 0, 0.05);
}
.ds-action-icon-base--weight-light:active,
.ds-action-icon-base--weight-light.ds-util-is-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-approve {
  color: #5e5e5e;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-approve:hover,
.ds-action-icon-base--weight-light.ds-action-icon-base--style-approve.ds-util-is-marked {
  background-color: rgba(0, 0, 0, 0.05);
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-approve:active,
.ds-action-icon-base--weight-light.ds-action-icon-base--style-approve.ds-util-is-active {
  background-color: #71c510;
  color: #fff;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-destructive {
  color: #5e5e5e;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-destructive:hover,
.ds-action-icon-base--weight-light.ds-action-icon-base--style-destructive.ds-util-is-marked {
  background-color: rgba(0, 0, 0, 0.05);
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-destructive:active,
.ds-action-icon-base--weight-light.ds-action-icon-base--style-destructive.ds-util-is-active {
  background-color: rgba(251, 13, 0, 0.05);
  color: #e20c00;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-chameleon {
  color: #fff;
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-chameleon:hover,
.ds-action-icon-base--weight-light.ds-action-icon-base--style-chameleon.ds-util-is-marked {
  background-color: rgba(255, 255, 255, 0.2);
}
.ds-action-icon-base--weight-light.ds-action-icon-base--style-chameleon:active,
.ds-action-icon-base--weight-light.ds-action-icon-base--style-chameleon.ds-util-is-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.ds-action-icon-base--weight-x-light {
  border: none;
}
.ds-action-icon-base--weight-x-light:hover,
.ds-action-icon-base--weight-x-light.ds-util-is-marked {
  background-color: transparent;
}
.ds-action-icon-base--weight-x-light:active,
.ds-action-icon-base--weight-x-light.ds-util-is-active {
  background-color: transparent;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-approve {
  color: #5e5e5e;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-approve:hover,
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-approve.ds-util-is-marked {
  background-color: transparent;
  color: #71c510;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-approve:active,
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-approve.ds-util-is-active {
  background-color: transparent;
  color: #63ad0e;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-destructive {
  color: #5e5e5e;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-destructive:hover,
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-destructive.ds-util-is-marked {
  background-color: transparent;
  color: #fb0d00;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-destructive:active,
.ds-action-icon-base--weight-x-light.ds-action-icon-base--style-destructive.ds-util-is-active {
  background-color: transparent;
  color: #e20c00;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-style-chameleon {
  color: #fff;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-style-chameleon:hover,
.ds-action-icon-base--weight-x-light.ds-action-icon-style-chameleon.ds-util-is-marked {
  background-color: transparent;
}
.ds-action-icon-base--weight-x-light.ds-action-icon-style-chameleon:active,
.ds-action-icon-base--weight-x-light.ds-action-icon-style-chameleon.ds-util-is-active {
  background-color: transparent;
}
.ds-action-icon-base:disabled,
.ds-action-icon-base.ds-util-is-disabled {
  pointer-events: none;
  user-select: none;
}
.ds-action-icon-base:disabled,
.ds-action-icon-base.ds-util-is-disabled,
.ds-action-icon-base:disabled:hover,
.ds-action-icon-base.ds-util-is-disabled:hover,
.ds-action-icon-base:disabled.ds-util-is-marked,
.ds-action-icon-base.ds-util-is-disabled.ds-util-is-marked {
  color: #c0c0c0;
}
.ds-action-icon-base:disabled:active:not(.ds-action-icon-base--weight-x-light),
.ds-action-icon-base.ds-util-is-disabled:active:not(.ds-action-icon-base--weight-x-light),
.ds-action-icon-base:disabled.ds-util-is-active:not(.ds-action-icon-base--weight-x-light),
.ds-action-icon-base.ds-util-is-disabled.ds-util-is-active:not(.ds-action-icon-base--weight-x-light) {
  background-color: rgba(0, 0, 0, 0.05);
}
.ds-action-icon-base--is-working:after {
  content: '';
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  border-radius: 100%;
  border: 2px solid currentColor;
  border-top-color: transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  animation: loading 0.5s infinite linear;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ds-action-icon-base--is-working:before {
  display: none;
}
.ds-action-icon-base--has-only-icon {
  position: relative;
  justify-content: left;
  font-size: 0;
  text-indent: 5000px;
  white-space: nowrap;
  overflow: hidden;
}
.ds-action-icon-base--has-only-icon:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: 0;
}
.ds-action-icon-base:before {
  color: currentColor;
}
.ds-avatar-base {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-family: "ProximaNova", sans-serif;
  text-transform: uppercase;
  overflow: hidden;
}
.ds-avatar-base:before,
.ds-avatar-base:after {
  box-sizing: border-box;
}
.ds-avatar-base--size-s {
  font-size: 12px;
  line-height: 16px;
  width: 24px;
  height: 24px;
}
.ds-avatar-base--size-xs {
  font-size: 10px;
  line-height: 16px;
  width: 18px;
  height: 18px;
}
.ds-avatar-base--content-position-helper {
  padding-top: 1px;
}
.ds-choose-base {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.ds-choose-base:before,
.ds-choose-base:after {
  box-sizing: border-box;
}
.ds-choose-base .ds-choose-base {
  padding-left: 20px;
}
.ds-choose-base .ds-tip--hint {
  margin-left: 20px;
}
.ds-choose-base__label {
  position: relative;
  align-items: center;
  cursor: pointer;
}
.ds-choose-base__label:hover .ds-choose-base__helper {
  border-color: #b3b3b3;
}
.ds-choose-base__label:empty .ds-choose-base__helper {
  margin-top: 0;
}
.ds-choose-base__label.ds-label--style-chameleon:hover .ds-choose-base__helper {
  border-color: rgba(255, 255, 255, 0.3);
  background: transparent;
}
.ds-choose-base__label.ds-label--style-chameleon .ds-choose-base__helper {
  border-color: rgba(255, 255, 255, 0.3);
  background: transparent;
}
.ds-choose-base__helper {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  width: 12px;
  height: 12px;
  margin-top: 3px;
  margin-right: 8px;
  border: 1px solid #cdcdcd;
  position: relative;
  flex-shrink: 0;
  background-color: #fff;
}
.ds-choose-base__helper:before,
.ds-choose-base__helper:after {
  box-sizing: border-box;
}
.ds-choose-base__helper:after {
  content: '';
  width: 4px;
  height: 4px;
  position: absolute;
  top: 3px;
  left: 3px;
}
.ds-choose-base__helper--no-child {
  margin: 0;
}
.ds-choose-base--text-monospace .ds-radio-button__label .ds-choose-base__helper {
  top: 0;
}
.ds-choose-base--is-disabled {
  pointer-events: none;
  opacity: 0.4;
  user-select: none;
}
.ds-choose-base--is-error .ds-choose-base__label:hover .ds-choose-base__helper {
  border-color: #ff3b30;
}
.ds-choose-base--is-error .ds-choose-base__label .ds-choose-base__helper {
  border-color: #ff3b30;
}
.ds-choose-base--stretched .ds-choose-base__label {
  width: 100%;
}
.ds-choose-base--switched-side .ds-choose-base__label .ds-choose-base__helper {
  margin-right: 0;
  order: 1;
}
.ds-choose-base--line-distributed .ds-choose-base__label {
  justify-content: space-between;
}
.ds-choose-base__input {
  display: none;
}
.ds-choose-base__input:checked + .ds-choose-base__label:hover .ds-choose-base__helper {
  background: #006cff;
}
.ds-choose-base--is-error .ds-choose-base__input:checked + .ds-choose-base__label:hover .ds-choose-base__helper {
  border-color: #ff3b30;
}
.ds-choose-base--is-error .ds-choose-base__input:checked + .ds-choose-base__label .ds-choose-base__helper {
  border-color: #ff3b30;
  background: #ff3b30;
}
.ds-choose-base__input:checked + .ds-choose-base__label .ds-choose-base__helper {
  border-color: #006cff;
  background: #006cff;
}
.ds-avatar {
  flex-shrink: 0;
  color: #fff;
  font-weight: 600;
}
.ds-avatar--size-s.ds-avatar--has-border {
  padding: 2px;
}
.ds-avatar--size-xs.ds-avatar--has-border {
  padding: 1px;
}
.ds-avatar--has-default-bg {
  background-color: #111111;
}
.ds-avatar--has-text-dark {
  color: rgba(0, 0, 0, 0.8);
}
.ds-avatar__photo {
  display: flex;
}
.ds-avatar__photo--rounded {
  border-radius: 50%;
}
.ds-avatar-placeholder {
  background-color: rgba(0, 0, 0, 0.07);
  color: #5e5e5e;
}
.ds-avatar-placeholder--has-placeholder {
  position: relative;
  justify-content: left;
  font-size: 0;
  text-indent: 5000px;
  white-space: nowrap;
  overflow: hidden;
}
.ds-avatar-placeholder--has-placeholder:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: 0;
}
.ds-avatar-placeholder:before {
  color: inherit;
}
.ds-avatar-placeholder--size-s:before {
  font-size: 10px;
}
.ds-avatar-placeholder--size-xs:before {
  font-size: 8px;
}
.ds-badge {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  text-indent: 0;
  display: inline-flex;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.ds-badge:before,
.ds-badge:after {
  box-sizing: border-box;
}
.ds-badge:before {
  position: static;
  color: inherit;
  vertical-align: middle;
  line-height: inherit;
  display: inline-block;
  font-size: 10px;
}
.ds-badge--type-text .ds-badge:before {
  margin-top: -1px;
  margin-right: 6px;
}
.ds-badge--type-text {
  padding-right: 6px;
  padding-left: 6px;
}
.ds-badge--type-icon {
  width: 18px;
  cursor: default;
}
.ds-badge--style-default {
  background-color: #006cff;
  color: #fff;
}
.ds-badge--style-neutral {
  background-color: rgba(0, 0, 0, 0.06);
  color: #777777;
}
.ds-badge--style-warning {
  background-color: #ffe080;
  color: #444;
}
.ds-badge--size-s {
  font-size: 12px;
  line-height: 16px;
  height: 18px;
}
.ds-badge--size-xs {
  font-size: 10px;
  line-height: 16px;
  height: 14px;
  text-transform: uppercase;
}
.ds-badge--space-left-s {
  margin-left: 6px;
}
.ds-badge--space-right-s {
  margin-right: 6px;
}
.ds-badge--stretched {
  width: 100%;
}
.ds-badge--is-clickable {
  cursor: pointer;
}
.ds-badge--is-working {
  background-color: #e6e6e6;
  color: #e6e6e6;
  overflow: hidden;
}
.ds-badge--is-working:after {
  content: '';
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 100%;
  border: 1px solid #919191;
  border-top-color: transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  animation: loading 0.5s infinite linear;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ds-badge--weight-light {
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
}
.ds-badge--weight-light.ds-badge-style-default {
  border-color: #006cff;
  color: #006cff;
}
.ds-badge--weight-light.ds-badge--style-neutral {
  border-color: rgba(0, 0, 0, 0.1);
  color: #777777;
}
.ds-badge--weight-light.ds-badge--style-warning {
  border-color: #ffe080;
  color: #444;
}
.ds-badge--weight-light.ds-badge--is-working {
  border-color: #e6e6e6;
  color: transparent;
}
.ds-btn {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: flex;
  height: 34px;
  padding: 0 20px;
  border: none;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
  white-space: pre;
  user-select: none;
}
.ds-btn:before,
.ds-btn:after {
  box-sizing: border-box;
}
.ds-btn:active,
.ds-btn:focus {
  outline: none;
}
.ds-btn[class*='icon-']:before {
  position: static;
  color: inherit;
  vertical-align: middle;
  line-height: inherit;
  margin-right: 6px;
}
.ds-btn--link-type {
  display: inline-flex;
}
.ds-btn--size-l {
  height: 50px;
  padding: 0 24px;
  font-size: 16px;
}
.ds-btn--size-s,
.ds-btn--size-xs {
  font-size: 12px;
}
.ds-btn--size-s[class*='icon-']:before,
.ds-btn--size-xs[class*='icon-']:before {
  margin-right: 5px;
}
.ds-btn--size-s {
  height: 24px;
  padding: 0 10px;
}
.ds-btn--size-xs {
  height: 18px;
  padding: 0 6px;
  font-weight: 400;
}
.ds-btn--stretched {
  display: flex;
  width: 100%;
}
.ds-btn--style-default {
  background-color: #006cff;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 2px 0;
  color: #fff;
}
.ds-btn--style-default:hover,
.ds-btn--style-default:focus {
  background-color: #0061e6;
}
.ds-btn--style-default:active {
  background-color: #004cb3;
}
.ds-btn--style-neutral {
  background-color: rgba(0, 0, 0, 0.06);
  color: #5e5e5e;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 0;
}
.ds-btn--style-neutral:hover,
.ds-btn--style-neutral:focus {
  background-color: rgba(0, 0, 0, 0.09);
}
.ds-btn--style-neutral:active {
  background-color: rgba(0, 0, 0, 0.12);
}
.ds-btn--style-approve {
  background-color: #71c510;
  color: #fff;
}
.ds-btn--style-approve:hover,
.ds-btn--style-approve:focus {
  background-color: #63ad0e;
}
.ds-btn--style-approve:active {
  background-color: #3b6608;
}
.ds-btn--style-chameleon {
  background-color: rgba(255, 255, 255, 0.1);
  color: #f3f3f3;
}
.ds-btn--style-chameleon:hover,
.ds-btn--style-chameleon:focus {
  background-color: rgba(255, 255, 255, 0.13);
}
.ds-btn--style-chameleon:active {
  background-color: rgba(255, 255, 255, 0.15);
}
.ds-btn--style-destructive {
  background-color: #fb0d00;
  color: #fff;
}
.ds-btn--style-destructive:hover,
.ds-btn--style-destructive:focus {
  background-color: #e20c00;
}
.ds-btn--style-destructive:active {
  background-color: #af0900;
}
.ds-btn--style-warning {
  background-color: #ffe080;
  color: #444;
}
.ds-btn--style-warning:hover,
.ds-btn--style-warning:focus {
  background-color: #ffc61a;
}
.ds-btn--style-warning:active {
  background-color: #e6ad00;
}
.ds-btn--style-code {
  background-color: #2b2b2b !important;
  color: #fff;
}
.ds-btn--style-code:hover,
.ds-btn--style-code:focus {
  background-color: #1e1e1e;
}
.ds-btn--style-code:active {
  background-color: #111111;
}
.ds-btn--weight-light {
  font-weight: 400;
}
.ds-btn--weight-light.ds-btn--style-default {
  box-shadow: inset 0 0 0 1px #99c4ff;
  background-color: transparent;
  color: #006cff;
}
.ds-btn--weight-light.ds-btn--style-default:hover,
.ds-btn--weight-light.ds-btn--style-default:focus {
  background-color: rgba(240, 246, 255, 0.6);
}
.ds-btn--weight-light.ds-btn--style-default:active {
  background-color: #f0f6ff;
  color: #006cff;
}
.ds-btn--weight-light.ds-btn--style-neutral {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  color: #5e5e5e;
}
.ds-btn--weight-light.ds-btn--style-neutral:hover,
.ds-btn--weight-light.ds-btn--style-neutral:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.ds-btn--weight-light.ds-btn--style-neutral:active {
  box-shadow: inset 0 0 0 1px #e6e6e6;
  background-color: rgba(0, 0, 0, 0.1);
}
.ds-btn--weight-light.ds-btn--style-approve {
  box-shadow: inset 0 0 0 1px #71c510;
  background-color: transparent;
  color: #56950c;
}
.ds-btn--weight-light.ds-btn--style-approve:hover,
.ds-btn--weight-light.ds-btn--style-approve:focus {
  background-color: #f0fde1;
}
.ds-btn--weight-light.ds-btn--style-approve:active {
  box-shadow: inset 0 0 0 1px #71c510;
  background-color: #71c510;
  color: #fff;
}
.ds-btn--weight-light.ds-btn--style-destructive {
  box-shadow: inset 0 0 0 1px #ff6b62;
  background-color: transparent;
  color: #fb0d00;
}
.ds-btn--weight-light.ds-btn--style-destructive:hover,
.ds-btn--weight-light.ds-btn--style-destructive:focus {
  background-color: #ffedec;
}
.ds-btn--weight-light.ds-btn--style-destructive:active {
  box-shadow: inset 0 0 0 1px #ff6b62;
  background-color: #ff6b62;
  color: #fff;
}
.ds-btn--weight-light.ds-btn--style-chameleon {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: #fff;
}
.ds-btn--weight-light.ds-btn--style-chameleon:hover,
.ds-btn--weight-light.ds-btn--style-chameleon:focus {
  background-color: rgba(255, 255, 255, 0.2);
}
.ds-btn--weight-light.ds-btn--style-chameleon:active {
  box-shadow: inset 0 0 0 1px transparent;
  background-color: rgba(255, 255, 255, 0.5);
}
.ds-btn--weight-light.ds-btn--style-warning {
  box-shadow: inset 0 0 0 1px #b38600;
  background-color: transparent;
  color: #806000;
}
.ds-btn--weight-light.ds-btn--style-warning:hover,
.ds-btn--weight-light.ds-btn--style-warning:focus {
  background-color: #fff9e5;
}
.ds-btn--weight-light.ds-btn--style-warning:active {
  box-shadow: inset 0 0 0 1px #b38600;
  background-color: #b38600;
  color: #fff;
}
.ds-btn:disabled,
.ds-btn--is-disabled {
  pointer-events: none;
  opacity: 1;
  user-select: none;
  box-shadow: none;
}
.ds-btn:disabled,
.ds-btn--is-disabled,
.ds-btn:disabled:hover,
.ds-btn--is-disabled:hover {
  background-color: #f3f3f3;
  color: #c0c0c0;
}
.ds-btn:disabled.ds-btn--weight-light,
.ds-btn--is-disabled.ds-btn--weight-light,
.ds-btn:disabled.ds-btn--weight-light:hover,
.ds-btn--is-disabled.ds-btn--weight-light:hover {
  box-shadow: inset 0 0 0 1px #e6e6e6;
  background-color: transparent;
  color: #a6a6a6;
}
.ds-btn:disabled.ds-btn--weight-light.ds-btn--style-chameleon,
.ds-btn--is-disabled.ds-btn--weight-light.ds-btn--style-chameleon,
.ds-btn:disabled.ds-btn--weight-light.ds-btn--style-chameleon:hover,
.ds-btn--is-disabled.ds-btn--weight-light.ds-btn--style-chameleon:hover {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.1);
}
.ds-btn--is-working {
  position: relative;
  color: transparent !important;
  pointer-events: none;
}
.ds-btn--is-working:after {
  content: '';
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  border-radius: 100%;
  border: 2px solid #fff;
  border-top-color: transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  animation: loading 0.5s infinite linear;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ds-btn--is-working[class*='icon-']:before {
  opacity: 0;
}
.ds-btn--is-working.ds-btn--size-l:after {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}
.ds-btn--is-working.ds-btn--size-s:after {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
}
.ds-btn--is-working.ds-btn--weight-light:after {
  border-color: #006cff;
}
.ds-btn--is-working.ds-btn--weight-light.ds-btn--style-approve:after {
  border-color: #56950c;
}
.ds-btn--is-working.ds-btn--weight-light.ds-btn--style-destructive:after {
  border-color: #fb0d00;
}
.ds-btn--is-working.ds-btn--weight-light.ds-btn--style-chameleon:after {
  border-color: #fff;
}
.ds-btn--is-working.ds-btn--weight-light.ds-btn--style-warning:after {
  border-color: #806000;
}
.ds-btn--is-working.ds-btn--style-neutral:after,
.ds-btn--is-working.ds-btn--weight-light.ds-btn--style-neutral:after {
  border-color: #5e5e5e;
}
.ds-btn--is-working:disabled:after,
.ds-btn--is-working.ds-btn--weight-light:disabled:after,
.ds-btn--is-working.ds-btn--is-disabled:after,
.ds-btn--is-working.ds-btn--weight-light.ds-btn--is-disabled:after {
  border-color: #5e5e5e;
}
.ds-btn-group {
  display: flex;
}
.ds-btn-group--direction-horizontal {
  flex-direction: row;
  align-items: center;
}
.ds-btn-group--direction-horizontal.ds-btn-group--stretched {
  width: 100%;
}
.ds-btn-group--direction-vertical {
  flex-direction: column;
}
.ds-btn-group--direction-vertical.ds-btn-group--stretched {
  height: 100%;
}
.ds-btn-group--stretched {
  justify-content: space-between;
}
.ds-btn-group--space-horizontal-s {
  gap: 6px;
}
.ds-btn-group--space-horizontal-m {
  gap: 10px;
}
.ds-btn-group--space-horizontal-l {
  gap: 16px;
}
.ds-btn-group--space-vertical-s {
  gap: 6px;
}
.ds-btn-group--space-vertical-m {
  gap: 10px;
}
.ds-btn-group--space-vertical-l {
  gap: 16px;
}
.ds-btn-group__item {
  list-style: none;
}
.ds-btn-icon {
  text-decoration: none;
}
.ds-btn-icon--has-state-indicator {
  width: 46px;
}
.ds-btn-icon--has-state-indicator:before {
  margin-left: -12px;
}
.ds-btn-icon--has-state-indicator:after {
  content: '\E094';
  color: #919191;
  font-size: 7px;
  text-align: center;
  font-family: 'uxpds-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 8px;
  text-indent: 0;
}
.ds-btn-icon--has-state-indicator.ds-btn-icon--size-s {
  width: 36px;
}
.ds-btn-icon--has-state-indicator.ds-btn-icon--size-s:after {
  right: 4px;
}
.ds-btn-icon--has-state-indicator.ds-btn-icon--size-xs {
  width: 30px;
}
.ds-btn-icon--has-state-indicator.ds-btn-icon--size-xs:after {
  right: 2px;
}
.ds-btn-icon--has-state-indicator:active:after,
.ds-btn-icon--has-state-indicator.ds-util-is-active:after {
  transform: rotate(180deg);
}
.ds-checkbox {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
}
.ds-checkbox:before,
.ds-checkbox:after {
  box-sizing: border-box;
}
.ds-checkbox:active,
.ds-checkbox:focus {
  outline: none;
}
.ds-checkbox .ds-choose-base__label .ds-choose-base__helper {
  border-radius: 2px;
  align-self: flex-start;
}
.ds-checkbox .ds-choose-base__input:checked + .ds-choose-base__label .ds-choose-base__helper:after {
  content: '\E295';
  color: #fff;
  font-size: 4px;
  text-align: center;
  font-family: 'uxpds-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ds-decoration-icon {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: inline-flex;
}
.ds-decoration-icon:before,
.ds-decoration-icon:after {
  box-sizing: border-box;
}
.ds-decoration-icon:before {
  display: flex;
  align-items: center;
}
.ds-decoration-icon--has-placeholder {
  font-size: 0;
}
.ds-heading-separator {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  display: flex;
  width: 100%;
  align-items: center;
}
.ds-heading-separator:before,
.ds-heading-separator:after {
  box-sizing: border-box;
}
.ds-heading-separator--text-uppercase {
  font-size: 10px;
  color: #b3b3b3;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.ds-heading-separator--text-center:after,
.ds-heading-separator--text-center:before {
  content: '';
  display: flex;
  height: 1px;
  background-color: #f3f3f3;
  flex-grow: 1;
}
.ds-heading-separator--text-on-left:after {
  content: '';
  display: flex;
  height: 1px;
  background-color: #f3f3f3;
  flex-grow: 1;
}
.ds-heading-separator--style-chameleon {
  color: #919191;
}
.ds-heading-separator--style-chameleon:after,
.ds-heading-separator--style-chameleon:before {
  background-color: rgba(255, 255, 255, 0.3);
}
.ds-heading-separator__text-helper--text-center {
  padding: 0 10px;
}
.ds-heading-separator__text-helper--text-on-left {
  padding-right: 10px;
}
.ds-hulk-select {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: block;
  width: 100%;
  position: relative;
}
.ds-hulk-select:before,
.ds-hulk-select:after {
  box-sizing: border-box;
}
.ds-hulk-select__header,
.ds-hulk-select__footer {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: block;
  margin: 0;
  padding: 6px 20px;
  color: #414141;
}
.ds-hulk-select__header:before,
.ds-hulk-select__footer:before,
.ds-hulk-select__header:after,
.ds-hulk-select__footer:after {
  box-sizing: border-box;
}
.ds-hulk-select__header:not(.ds-hulk-select__footer--inverted) {
  border-bottom: 1px solid #f3f3f3;
}
.ds-hulk-select__header--inverted {
  border-top: 1px solid #f3f3f3;
}
.ds-hulk-select__footer:not(.ds-hulk-select__footer--inverted) {
  border-top: 1px solid #f3f3f3;
}
.ds-hulk-select__footer--inverted {
  border-bottom: 1px solid #f3f3f3;
}
.ds-hulk-select .ds-hulk-select__input .ds-input__field {
  padding-right: 10px;
}
.ds-hulk-select__input {
  position: relative;
}
.ds-hulk-select__input-chevron {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.ds-hulk-select__input-chevron:before,
.ds-hulk-select__input-chevron:after {
  box-sizing: border-box;
}
.ds-hulk-select__input-chevron:before {
  content: '\E094';
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
  text-align: center;
  font-family: 'uxpds-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ds-hulk-select--style-chameleon .ds-hulk-select__input-chevron:before {
  color: rgba(255, 255, 255, 0.4);
}
.ds-util-is-disabled .ds-hulk-select__input-chevron {
  opacity: 0;
  visibility: hidden;
}
.ds-util-is-active .ds-hulk-select__input-chevron {
  transform: translateY(-50%) rotate(-180deg);
}
.ds-hulk-select__input-autosuggest {
  position: relative;
}
.ds-hulk-select__input-autosuggest-helper {
  width: 100%;
  padding-right: 10px;
  position: absolute;
  padding-block: 1px;
  top: 0;
  z-index: 1;
  color: #b3b3b3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@supports (-moz-appearance:meterbar) {
  .ds-hulk-select__input-autosuggest-helper {
    top: 2px;
  }
}
.ds-hulk-select__input-autosuggest-helper .ds-util-is-hidden {
  color: transparent;
}
.ds-hulk-select__container {
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  border-radius: 2px;
  position: fixed;
  z-index: 2147483547;
  top: 0;
  left: 0;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}
.ds-hulk-select__container:before,
.ds-hulk-select__container:after {
  box-sizing: border-box;
}
.ds-hulk-select__container:active,
.ds-hulk-select__container:focus {
  outline: none;
}
.ds-hulk-select__container--visible {
  visibility: visible;
}
.ds-hulk-select__container--has-preview {
  padding-left: 170px;
}
.ds-hulk-select__container--searching {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ds-hulk-select__container--searching .ds-hulk-select__group {
  padding: 0;
}
.ds-hulk-select__container--searching .ds-hulk-select__footer:not(.ds-hulk-select__footer--inverted) {
  padding-bottom: 0;
}
.ds-hulk-select__options {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: auto;
}
.ds-hulk-select__options:before,
.ds-hulk-select__options:after {
  box-sizing: border-box;
}
.ds-hulk-select__separator {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  width: 100%;
  min-height: 1px;
  margin: 5px 0;
  border: none;
  position: relative;
  z-index: 1;
  align-items: center;
}
.ds-hulk-select__separator:before,
.ds-hulk-select__separator:after {
  box-sizing: border-box;
}
.ds-hulk-select__separator--empty {
  margin: 0;
}
.ds-hulk-select__separator-item {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 10px;
  color: #848484;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  padding: 0 10px;
  background-color: #fff;
}
.ds-hulk-select__separator-item:before,
.ds-hulk-select__separator-item:after {
  box-sizing: border-box;
}
.ds-hulk-select__separator:before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: -1;
  transform: translateY(-1px);
  background-color: #f0f0f0;
}
.ds-hulk-select__group {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: block;
  width: calc(100% - 10px * 2);
  padding: 6px 0;
  margin-left: auto;
  margin-right: auto;
  cursor: default;
  list-style: none;
}
.ds-hulk-select__group:before,
.ds-hulk-select__group:after {
  box-sizing: border-box;
}
.ds-hulk-select__container--has-preview .ds-hulk-select__group {
  width: auto;
}
.ds-hulk-select__preview-container {
  overflow: hidden;
  display: flex;
  width: 170px;
  height: inherit;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
}
.ds-hulk-select__empty-state,
.ds-hulk-select__item {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}
.ds-hulk-select__empty-state:before,
.ds-hulk-select__item:before,
.ds-hulk-select__empty-state:after,
.ds-hulk-select__item:after {
  box-sizing: border-box;
}
.ds-hulk-select__empty-state {
  padding: 0 20px;
  color: #919191;
}
.ds-hulk-select__empty-state + .ds-hulk-select__group {
  display: none;
}
.ds-hulk-select__item {
  padding: 1px 10px 0 24px;
  border: 1px solid #fff;
  border-radius: 2px;
  position: relative;
  align-items: center;
  color: #414141;
}
.ds-hulk-select__item-single {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: block;
  width: 100%;
}
.ds-hulk-select__item-single:before,
.ds-hulk-select__item-single:after {
  box-sizing: border-box;
}
.ds-hulk-select__item--hidden {
  display: none;
}
.ds-hulk-select__item.ds-util-is-marked,
.ds-hulk-select__item--selected {
  z-index: 1;
}
.ds-hulk-select__item.ds-util-is-marked:not(.selected) {
  border-color: #f3f3f3;
  background-color: #f3f3f3;
}
.ds-hulk-select__item--selected {
  border-color: #f3f3f3;
}
.ds-hulk-select__item--selected:before {
  content: '\E295';
  color: #444;
  font-size: 5px;
  text-align: center;
  font-family: 'uxpds-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: calc(50% - 5px / 2);
  left: 10px;
}
.ds-hulk-select__item-preview {
  display: none;
}
.ds-hulk-select__item-preview--is-visible {
  display: block;
}
.ds-hulk-select--fit-to-option .ds-hulk-select__container,
.ds-hulk-select--fit-to-option .ds-hulk-select__options,
.ds-hulk-select--fit-to-option .ds-hulk-select__item {
  width: auto;
}
.ds-hulk-select--weight-light .ds-hulk-select__input-chevron {
  right: 0 !important;
}
.ds-hulk-select--size-s {
  height: 24px;
}
.ds-hulk-select--size-s .ds-hulk-select__input-chevron {
  right: 6px;
}
.ds-hulk-select--size-m {
  height: 34px;
}
.ds-hulk-select--size-l {
  height: 50px;
}
.ds-hulk-select--size-l .ds-hulk-select__input-chevron {
  right: 20px;
}
.ds-hulk-select--width-xxs {
  width: 30px;
}
.ds-hulk-select--width-xs {
  width: 70px;
}
.ds-hulk-select--width-s {
  width: 150px;
}
.ds-hulk-select--width-m {
  width: 230px;
}
.ds-hulk-select--width-l {
  width: 310px;
}
.ds-hulk-select--width-xl {
  width: 310px;
}
.ds-inline-edit {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: block;
  max-width: 100%;
  text-decoration: inherit;
}
.ds-inline-edit:before,
.ds-inline-edit:after {
  box-sizing: border-box;
}
.ds-inline-edit--is-inline {
  display: inline;
}
.ds-inline-edit:focus,
.ds-inline-edit__field:focus,
.ds-inline-edit:active,
.ds-inline-edit__field:active {
  outline: none;
}
.ds-inline-edit__field {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-variant: inherit;
  line-height: inherit;
  text-decoration: inherit;
  text-transform: inherit;
  display: inline-block;
  max-width: 100%;
  margin-top: -0.25em;
  padding-top: 0.25em;
  vertical-align: top;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3) 50%, transparent 50%);
  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: 2px 1px;
  cursor: default;
}
.ds-inline-edit__field:before,
.ds-inline-edit__field:after {
  box-sizing: border-box;
}
.ds-inline-edit__field:hover {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 50%, transparent 50%);
}
.ds-inline-edit__field--is-active,
.ds-inline-edit__field--is-active:focus,
.ds-inline-edit__field--is-active:active,
.ds-inline-edit__field--is-active:hover {
  background-image: none;
  text-overflow: initial;
  cursor: auto;
  user-select: initial;
}
.ds-inline-edit__field--is-active:empty {
  display: inline-block;
  overflow: initial;
}
.ds-inline-edit__field--is-active:empty:before {
  content: '';
  display: inline-block;
  min-width: 1px;
  height: 1px;
  overflow: hidden;
}
.ds-inline-edit__field--is-active:empty:after {
  display: none;
}
.ds-inline-edit__field--is-disabled {
  pointer-events: none;
}
.ds-inline-edit__field--is-disabled,
.ds-inline-edit__field--weight-x-light,
.ds-inline-edit__field--is-disabled:hover,
.ds-inline-edit__field--weight-x-light:hover {
  background-image: none;
}
.ds-inline-edit__field--style-chameleon {
  background-image: linear-gradient(to right, rgba(218, 218, 218, 0.3) 50%, transparent 50%);
}
.ds-inline-edit__field--style-chameleon:hover {
  background-image: linear-gradient(to right, rgba(230, 230, 230, 0.8) 50%, transparent 50%);
}
.ds-inline-edit__field--weight-light {
  background-image: none;
}
.ds-inline-edit__field--has-placeholder:empty:after {
  content: attr(data-placeholder);
}
.ds-inline-edit__field--has-text-cursor {
  cursor: text;
}
.ds-inline-edit__field--wrapped {
  display: inline;
  text-overflow: initial;
  white-space: normal;
  overflow: auto;
}
.ds-input20 {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  flex-direction: row;
  color: #5e5e5e;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  cursor: text;
}
.ds-input20:before,
.ds-input20:after {
  box-sizing: border-box;
}
.ds-input20--weight-heavy {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}
.ds-input20--weight-light {
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.ds-input20--style-default {
  border-color: #e6e6e6;
  color: #5e5e5e;
}
.ds-util-is-active.ds-input20--style-default:not(.ds-input20--is-readonly),
.ds-input20--static:focus-within.ds-input20--style-default:not(.ds-input20--is-readonly) {
  border-color: #006cff;
}
.ds-input20--style-chameleon {
  border-color: rgba(243, 243, 243, 0.3);
  background-color: transparent;
  color: #dadada;
}
.ds-input20--static:focus-within.ds-input20--style-chameleon:not(.ds-input20--is-readonly) {
  border-color: rgba(255, 255, 255, 0.5);
}
.ds-input20--style-chameleon .ds-input20__field::placeholder,
.ds-input20__field--is-disabled .ds-input20--style-chameleon .ds-input20__field::placeholder {
  color: rgba(179, 179, 179, 0.4);
}
.ds-input20--style-error {
  border-color: #ff3b30;
  color: #ff3b30;
}
.ds-input20--style-transparent {
  background-color: transparent;
}
.ds-input20--size-s {
  height: 24px;
  line-height: 16px;
}
.ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field {
  padding-top: 3px;
  padding-bottom: 3px;
}
:not(.ds-input20--has-end-element).ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field,
.ds-input20--is-end-element-on-focus:not(.ds-util-is-active).ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field,
.ds-input20--is-end-element-on-focus.ds-input20--static:not(:focus-within).ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field {
  padding-right: 6px;
}
:not(.ds-input20--has-start-element).ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field,
.ds-input20--is-start-element-on-focus:not(.ds-util-is-active).ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field,
.ds-input20--is-start-element-on-focus.ds-input20--static:not(:focus-within).ds-input20--weight-heavy.ds-input20--size-s .ds-input20__field {
  padding-left: 6px;
}
.ds-input20--size-m {
  height: 34px;
  line-height: 21px;
}
:not(.ds-input20--has-end-element).ds-input20--weight-heavy.ds-input20--size-m .ds-input20__field,
.ds-input20--is-end-element-on-focus:not(.ds-util-is-active).ds-input20--weight-heavy.ds-input20--size-m .ds-input20__field,
.ds-input20--is-end-element-on-focus.ds-input20--static:not(:focus-within).ds-input20--weight-heavy.ds-input20--size-m .ds-input20__field {
  padding-right: 10px;
}
:not(.ds-input20--has-start-element).ds-input20--weight-heavy.ds-input20--size-m .ds-input20__field,
.ds-input20--is-start-element-on-focus:not(.ds-util-is-active).ds-input20--weight-heavy.ds-input20--size-m .ds-input20__field,
.ds-input20--is-start-element-on-focus.ds-input20--static:not(:focus-within).ds-input20--weight-heavy.ds-input20--size-m .ds-input20__field {
  padding-left: 10px;
}
.ds-input20--size-l {
  height: 50px;
  font-size: 16px;
  line-height: 24px;
}
.ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field {
  padding-top: 12px;
  padding-bottom: 12px;
}
:not(.ds-input20--has-end-element).ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field,
.ds-input20--is-end-element-on-focus:not(.ds-util-is-active).ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field,
.ds-input20--is-start-element-on-focus.ds-input20--static:not(:focus-within).ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field {
  padding-right: 20px;
}
:not(.ds-input20--has-start-element).ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field,
.ds-input20--is-start-element-on-focus:not(.ds-util-is-active).ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field,
.ds-input20--is-end-element-on-focus.ds-input20--static:not(:focus-within).ds-input20--weight-heavy.ds-input20--size-l .ds-input20__field {
  padding-left: 20px;
}
.ds-input20--size-l .ds-input20__field.ds-input20__field--text-monospace {
  font-size: 15px;
}
.ds-input20--width-xxs {
  width: 30px;
}
.ds-input20--width-xs {
  width: 70px;
}
.ds-input20--width-s {
  width: 150px;
}
.ds-input20--width-m {
  width: 230px;
}
.ds-input20--width-l {
  width: 310px;
}
.ds-input20--width-xl {
  width: 310px;
}
.ds-input20--width-stretched {
  width: 100%;
}
.ds-input20--is-readonly {
  border-color: #f6f6f6;
  box-shadow: none;
}
.ds-input20--is-readonly.ds-input20--weight-heavy {
  background-color: #f6f6f6;
}
.ds-input20--is-readonly.ds-input20--style-chameleon {
  border-color: transparent;
}
.ds-input20--is-readonly.ds-input20--style-chameleon.ds-input20--weight-heavy {
  background-color: rgba(243, 243, 243, 0.1);
}
.ds-input20--is-disabled {
  pointer-events: none;
  opacity: 1;
  user-select: none;
  color: #5e5e5e;
  -webkit-text-fill-color: #5e5e5e;
  border-color: transparent !important;
  box-shadow: none;
}
.ds-input20__field {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}
.ds-input20__field:before,
.ds-input20__field:after {
  box-sizing: border-box;
}
.ds-input20__field:active,
.ds-input20__field:focus {
  outline: none;
}
.ds-input20__field::-webkit-inner-spin-button,
.ds-input20__field::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}
.ds-input20__field--is-inactive.ds-input20__field::selection {
  color: inherit;
  background-color: transparent;
}
:not(.ds-input20__field--is-inactive).ds-input20__field::selection {
  color: #fff;
  background-color: #006cff;
}
.ds-input20__field::placeholder,
.ds-input20__field--is-disabled .ds-input20__field::placeholder {
  color: #b3b3b3;
}
.ds-input20__field--text-ellipsis {
  text-overflow: ellipsis;
}
.ds-input20__field--text-monospace {
  font-size: 13px;
  font-family: "SourceCodePro", "Courier New", Courier, monospace;
}
.ds-input20__field--text-uppercase {
  text-transform: uppercase;
}
.ds-input20__field--text-center {
  text-align: center;
}
.ds-input20__field--text-on-right {
  text-align: right;
}
.ds-input20__extra-element {
  display: flex;
  height: 100%;
  flex-grow: 0;
  align-items: center;
  flex-shrink: 0;
  line-height: inherit;
}
.ds-input20--size-s .ds-input20__extra-element--end-element {
  padding-left: 3px;
}
.ds-input20--weight-heavy.ds-input20--size-s .ds-input20__extra-element--end-element {
  padding-right: 6px;
}
.ds-input20--size-m .ds-input20__extra-element--end-element {
  padding-left: 5px;
}
.ds-input20--weight-heavy.ds-input20--size-m .ds-input20__extra-element--end-element {
  padding-right: 10px;
}
.ds-input20--size-l .ds-input20__extra-element--end-element {
  padding-left: 10px;
}
.ds-input20--weight-heavy.ds-input20--size-l .ds-input20__extra-element--end-element {
  padding-right: 20px;
}
.ds-input20__extra-element--end-element:not(:empty):before {
  order: 9999;
}
.ds-input20--size-s .ds-input20__extra-element--end-element:not(:empty):before {
  margin-left: 3px;
}
.ds-input20--size-m .ds-input20__extra-element--end-element:not(:empty):before {
  margin-left: 5px;
}
.ds-input20--size-l .ds-input20__extra-element--end-element:not(:empty):before {
  margin-left: 10px;
}
.ds-input20--size-s .ds-input20__extra-element--start-element {
  padding-right: 3px;
}
.ds-input20--weight-heavy.ds-input20--size-s .ds-input20__extra-element--start-element {
  padding-left: 6px;
}
.ds-input20--size-m .ds-input20__extra-element--start-element {
  padding-right: 5px;
}
.ds-input20--weight-heavy.ds-input20--size-m .ds-input20__extra-element--start-element {
  padding-left: 10px;
}
.ds-input20--size-l .ds-input20__extra-element--start-element {
  padding-right: 10px;
}
.ds-input20--weight-heavy.ds-input20--size-l .ds-input20__extra-element--start-element {
  padding-left: 20px;
}
.ds-input20--size-s .ds-input20__extra-element--start-element:not(:empty):before {
  margin-right: 3px;
}
.ds-input20--size-m .ds-input20__extra-element--start-element:not(:empty):before {
  margin-right: 5px;
}
.ds-input20--size-l .ds-input20__extra-element--start-element:not(:empty):before {
  margin-right: 10px;
}
.ds-input20--static:not(:focus-within) .ds-input20__extra-element--is-visible-on-focus,
.ds-input20:not(.ds-util-is-active) .ds-input20__extra-element--is-visible-on-focus {
  display: none;
}
.ds-input {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
}
.ds-input:before,
.ds-input:after {
  box-sizing: border-box;
}
.ds-input.with-icon-on-left,
.ds-input.with-icon-on-right {
  position: relative;
}
.ds-input .ds-input__field {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  width: 100%;
  height: 34px;
  padding-right: 10px;
  padding-left: 10px;
  border-color: #e6e6e6;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  background: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #5e5e5e;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  line-height: 21px;
  cursor: text;
}
.ds-input .ds-input__field:before,
.ds-input .ds-input__field:after {
  box-sizing: border-box;
}
.ds-input .ds-input__field:active,
.ds-input .ds-input__field:focus {
  outline: none;
}
.with-icon-on-left.ds-input .ds-input__field {
  padding-left: 30px !important;
}
.ds-icon--size-s.with-icon-on-left.ds-input .ds-input__field {
  padding-left: 20px !important;
}
.ds-icon--size-xs.with-icon-on-left.ds-input .ds-input__field {
  padding-left: 17px !important;
}
.with-icon-on-right.ds-input .ds-input__field {
  padding-right: 30px !important;
}
.ds-icon--size-s.with-icon-on-right.ds-input .ds-input__field {
  padding-right: 20px !important;
}
.ds-icon--size-xs.with-icon-on-right.ds-input .ds-input__field {
  padding-right: 17px !important;
}
.ds-input .ds-input__field::placeholder {
  color: #b3b3b3;
}
.ds-input .ds-input__field::selection {
  background-color: #006cff;
  color: #fff;
}
.ds-input .ds-input__field:active:not(.ds-input__field--is-readonly),
.ds-input .ds-input__field:focus:not(.ds-input__field--is-readonly) {
  border-color: #006cff;
}
.ds-input .ds-input__field:disabled {
  pointer-events: none;
  opacity: 1;
  user-select: none;
  color: #5e5e5e;
  -webkit-text-fill-color: #5e5e5e;
  border-color: transparent !important;
  box-shadow: none;
}
.ds-input .ds-input__field::-webkit-inner-spin-button,
.ds-input .ds-input__field::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}
.ds-input .ds-input__field--is-readonly {
  border-color: #f6f6f6;
  background-color: #f6f6f6;
  box-shadow: none;
}
.ds-input .ds-input__field--is-readonly.ds-input__field--style-chameleon {
  border-color: transparent;
  background-color: rgba(243, 243, 243, 0.1);
}
.ds-input .ds-input__field--hasBtnIconIndent {
  padding-left: 40px;
}
.ds-input .ds-input__field--hasBtnIconRightIndent {
  padding-right: 40px;
}
.ds-input .ds-input__field--size-s {
  height: 24px;
  padding: 3px 6px;
  line-height: 16px;
}
.ds-input .ds-input__field--size-s.ds-input__field--text-monospace {
  font-family: "SourceCodePro", "Courier New", Courier, monospace;
  font-size: 11px;
  line-height: 16px;
}
.ds-input .ds-input__field--size-s.ds-input__field--hasBtnIconIndent {
  padding-left: 30px;
}
.ds-input .ds-input__field--size-s.ds-input__field--hasBtnIconRightIndent {
  padding-right: 30px;
}
.ds-input .ds-input__field--size-l {
  height: 50px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 24px;
}
.ds-input .ds-input__field--size-l.ds-input__field--hasBtnIconIndent {
  padding-left: 44px;
}
.ds-input .ds-input__field--size-l.ds-input__field--hasBtnIconRightIndent {
  padding-right: 44px;
}
.ds-input .ds-input__field--style-chameleon {
  border-color: rgba(243, 243, 243, 0.3);
  background: transparent;
  color: #dadada;
}
.ds-input .ds-input__field--style-chameleon::placeholder {
  color: rgba(179, 179, 179, 0.4);
}
.ds-input .ds-input__field--style-chameleon:active:not(.ds-input__field--is-readonly),
.ds-input .ds-input__field--style-chameleon:focus:not(.ds-input__field--is-readonly) {
  border-color: rgba(255, 255, 255, 0.5);
}
.ds-input .ds-input__field--style-transparent {
  background: transparent;
}
.ds-input .ds-input__field--weight-light {
  padding: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
}
.ds-input .ds-input__field--weight-light.size-l {
  padding-right: 0;
  padding-left: 0;
}
.with-icon-on-left.ds-input .ds-input__field--weight-light {
  padding-left: 20px !important;
}
.ds-icon--size-s.with-icon-on-left.ds-input .ds-input__field--weight-light {
  padding-left: 10px !important;
}
.ds-icon--size-xs.with-icon-on-left.ds-input .ds-input__field--weight-light {
  padding-left: 7px !important;
}
.with-icon-on-right.ds-input .ds-input__field--weight-light {
  padding-right: 20px !important;
}
.ds-icon--size-s.with-icon-on-right.ds-input .ds-input__field--weight-light {
  padding-right: 10px !important;
}
.ds-icon--size-xs.with-icon-on-right.ds-input .ds-input__field--weight-light {
  padding-right: 7px !important;
}
.ds-input .ds-input__field--text-on-right {
  text-align: right;
}
.ds-input .ds-input__field--text-center {
  text-align: center;
}
.ds-input .ds-input__field--text-uppercase {
  text-transform: uppercase;
}
.ds-input .ds-input__field--text-monospace {
  font-family: "SourceCodePro", "Courier New", Courier, monospace;
}
.ds-input .ds-input__field--is-error {
  border-color: #ff3b30;
  color: #ff3b30;
}
.ds-input .ds-input__field--is-inactive {
  user-select: none;
}
.ds-input .ds-input__field--is-ellipsis {
  text-overflow: ellipsis;
}
.ds-input--width-xs {
  width: 70px;
}
.ds-input--width-s {
  width: 150px;
}
.ds-input--width-m {
  width: 230px;
}
.ds-input--width-l {
  width: 310px;
}
.ds-input--width-xl {
  width: 310px;
}
.ds-input--width-stretched {
  width: 100%;
}
.ds-input-copy {
  position: relative;
}
.ds-input-copy--width-xxs {
  width: 30px;
}
.ds-input-copy--width-xs {
  width: 70px;
}
.ds-input-copy--width-s {
  width: 150px;
}
.ds-input-copy--width-m {
  width: 230px;
}
.ds-input-copy--width-l {
  width: 310px;
}
.ds-input-copy--width-xl {
  width: 310px;
}
.ds-input-copy__copiedInfo {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 2;
  transform: translateY(-50%);
}
.ds-input-copy__copyField {
  position: relative;
  z-index: 1;
}
.ds-input-copy__copyBtn {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  transform: translateY(-50%);
}
.ds-item-list__item-wrapper {
  display: grid;
  grid-template-areas: "option removeBtn";
  grid-template-columns: 1fr max-content;
}
.ds-item-list__item-name {
  grid-area: option;
}
.ds-item-list__remove-btn {
  margin: -2px -7px -1px 0;
  grid-area: removeBtn;
}
.ds-item-list__new-item-field {
  margin-top: 6px;
}
.ds-item-list__enter-icon:before {
  color: #c0c0c0;
}
.ds-label {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #6a6a6a;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  text-indent: 0;
  display: flex !important;
}
.ds-label:before,
.ds-label:after {
  box-sizing: border-box;
}
.ds-label--text-monospace {
  font-family: "SourceCodePro", "Courier New", Courier, monospace;
  font-size: 13px;
  line-height: 21px;
  color: #5e5e5e;
}
.ds-label--has-inherit-style {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-variant: inherit;
  line-height: inherit;
  text-decoration: inherit;
  text-transform: inherit;
}
.ds-label--style-chameleon {
  color: #dadada;
}
.ds-link {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: inline;
  max-width: 100%;
  color: #006cff;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
}
.ds-link:before,
.ds-link:after {
  box-sizing: border-box;
}
.ds-link:active .ds-link__text {
  box-shadow: none !important;
}
.ds-link[class*='ds-icon']:before {
  position: static;
  color: inherit;
  vertical-align: middle;
  line-height: inherit;
  display: inline-block;
  margin-top: -2px;
  margin-right: 6px;
}
.ds-link:not(.ds-link--inverted-style):not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(0, 108, 255, 0.3);
}
.ds-link--style-contextual.ds-link:not(.ds-link--inverted-style):not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 currentColor;
}
.ds-link--style-chameleon.ds-link:not(.ds-link--inverted-style):not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(230, 230, 230, 0.3);
}
.ds-link--style-neutral.ds-link:not(.ds-link--inverted-style):not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(94, 94, 94, 0.3);
}
.ds-link--style-destructive.ds-link:not(.ds-link--inverted-style):not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(251, 13, 0, 0.3);
}
:hover.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text,
.ds-util-is-marked.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(0, 108, 255, 0.5);
}
:hover.ds-link--style-contextual.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text,
.ds-util-is-marked.ds-link--style-contextual.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 currentColor;
}
:hover.ds-link--style-chameleon.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text,
.ds-util-is-marked.ds-link--style-chameleon.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(230, 230, 230, 0.5);
}
:hover.ds-link--style-neutral.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text,
.ds-util-is-marked.ds-link--style-neutral.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(71, 71, 71, 0.5);
}
:hover.ds-link--style-destructive.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text,
.ds-util-is-marked.ds-link--style-destructive.ds-link:not(.ds-util-is-working):not(.ds-util-is-disabled) .ds-link__text {
  box-shadow: inset 0 -1px 0 0 rgba(201, 11, 0, 0.5);
}
.ds-link--ellipsis-style {
  display: inline-flex;
  align-items: center;
}
.ds-link--style-contextual,
.ds-link--style-contextual:hover,
.ds-link--style-contextual.ds-util-is-marked {
  color: inherit;
}
.ds-link--style-chameleon {
  color: #fff;
}
.ds-link--style-chameleon:hover,
.ds-link--style-chameleon.ds-util-is-marked {
  color: rgba(255, 255, 255, 0.8);
}
.ds-link--style-neutral {
  color: #5e5e5e;
}
.ds-link--style-neutral:hover,
.ds-link--style-neutral.ds-util-is-marked {
  color: #474747;
}
.ds-link--style-destructive {
  color: #fb0d00;
}
.ds-link--style-destructive:hover,
.ds-link--style-destructive.ds-util-is-marked {
  color: #c90b00;
}
.ds-link.ds-util-is-disabled {
  color: #c0c0c0;
  pointer-events: none;
}
.ds-link.ds-util-is-disabled.ds-link--style-chameleon {
  color: rgba(255, 255, 255, 0.1);
}
.ds-link.ds-util-is-disabled.ds-link--style-contextual {
  opacity: 0.5;
  color: inherit;
}
.ds-link.ds-util-is-working {
  display: inline-block;
  position: relative;
  color: transparent;
  pointer-events: none;
}
.ds-link.ds-util-is-working:after {
  content: '';
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  border-radius: 100%;
  border: 2px solid #006cff;
  border-top-color: transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  animation: loading 0.5s infinite linear;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ds-link.ds-util-is-working[class*='ds-icon']:before {
  opacity: 0;
}
.ds-link.ds-util-is-working.ds-link--style-chameleon:after {
  border-color: #fff;
}
.ds-link.ds-util-is-working.ds-link--style-neutral:after {
  border-color: #5e5e5e;
}
.ds-link.ds-util-is-working.ds-link--style-destructive:after {
  border-color: #fb0d00;
}
.ds-link__ellipsis-helper {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: inline-flex;
  max-width: 100%;
  min-width: 0;
}
.ds-link__ellipsis-helper:before,
.ds-link__ellipsis-helper:after {
  box-sizing: border-box;
}
.ds-link__ellipsis-helper .ds-link__text {
  line-height: 100%;
}
.ds-link__text {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: inline;
  vertical-align: top;
}
.ds-link__text:before,
.ds-link__text:after {
  box-sizing: border-box;
}
.ds-radio-button {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
}
.ds-radio-button:before,
.ds-radio-button:after {
  box-sizing: border-box;
}
.ds-radio-button:active,
.ds-radio-button:focus {
  outline: none;
}
.ds-radio-button .ds-choose-base__label .ds-choose-base__helper {
  border-radius: 50%;
  align-self: flex-start;
}
.ds-radio-button .ds-choose-base__input:checked + .ds-choose-base__label .ds-choose-base__helper:after {
  border-radius: 50%;
  background-color: #fff;
}
.ds-radio-group {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
}
.ds-radio-group:before,
.ds-radio-group:after {
  box-sizing: border-box;
}
.ds-radio-group__item {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  list-style: none;
}
.ds-radio-group__item:before,
.ds-radio-group__item:after {
  box-sizing: border-box;
}
.ds-switcher {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  line-height: 21px;
  color: #5e5e5e;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
}
.ds-switcher:before,
.ds-switcher:after {
  box-sizing: border-box;
}
.ds-switcher--stretched {
  width: 100%;
}
.ds-switcher--size-s {
  height: 24px;
}
.ds-switcher--size-s .ds-switcher__label {
  padding: 0 10px;
}
.ds-switcher--size-m {
  height: 34px;
}
.ds-switcher--size-m .ds-switcher__label {
  padding: 0 16px;
}
.ds-switcher--is-disabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
  cursor: default;
}
.ds-switcher__input {
  display: none;
}
.ds-switcher__input:checked ~ .ds-switcher__options-wrapper [attr-option="checked"] {
  pointer-events: none;
  background-color: #f0f0f0;
  cursor: default;
}
.ds-switcher__input:not(:checked) ~ .ds-switcher__options-wrapper [attr-option="unchecked"] {
  pointer-events: none;
  background-color: #f0f0f0;
  cursor: default;
}
.ds-switcher__options-wrapper {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 2px;
  border: 1px solid #e6e6e6;
}
.ds-switcher__option {
  list-style: none;
}
.ds-switcher__option--is-active {
  pointer-events: none;
  background-color: #f0f0f0;
  cursor: default;
}
.ds-switcher__option--has-separator {
  border-left: 1px solid #e6e6e6;
}
.ds-switcher__label {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ds-switcher__label::before {
  margin-right: 6px;
}
.ds-textarea {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: block;
  width: 100%;
  padding: 5.5px 10px;
  border-color: #e6e6e6;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  background: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #5e5e5e;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  line-height: 21px;
  cursor: text;
  resize: none;
}
.ds-textarea:before,
.ds-textarea:after {
  box-sizing: border-box;
}
.ds-textarea:active,
.ds-textarea:focus {
  outline: none;
}
.ds-textarea.ds-util-is-disabled {
  pointer-events: none;
  opacity: 1;
  user-select: none;
  color: #5e5e5e;
  -webkit-text-fill-color: #5e5e5e;
}
.ds-textarea::selection {
  background-color: #006cff;
  color: #fff;
}
.ds-textarea::placeholder {
  color: #b3b3b3;
}
.ds-textarea:active:not([readonly]),
.ds-textarea:focus:not([readonly]) {
  border-color: #006cff;
}
.ds-textarea[readonly] {
  border-color: #f6f6f6;
  background-color: #f6f6f6;
  box-shadow: none;
}
.ds-textarea--is-error {
  border-color: #ff3b30;
  color: #ff3b30;
}
.ds-textarea--style-chameleon {
  border-color: rgba(243, 243, 243, 0.3);
  background: transparent;
  color: #dadada;
}
.ds-textarea--style-chameleon::placeholder {
  color: rgba(179, 179, 179, 0.4);
}
.ds-textarea--style-chameleon:active,
.ds-textarea--style-chameleon:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.ds-textarea--style-transparent {
  background: transparent;
}
.ds-textarea--size-s {
  padding: 3px 6px;
  line-height: 16px;
}
.ds-textarea--size-s.ds-textarea--text-monospace {
  font-family: "SourceCodePro", "Courier New", Courier, monospace;
  font-size: 11px;
  line-height: 16px;
}
.ds-textarea--size-l {
  padding: 12px 20px;
  font-size: 16px;
  line-height: 24px;
}
.ds-textarea--width-xxs {
  width: 30px;
}
.ds-textarea--width-xs {
  width: 70px;
}
.ds-textarea--width-s {
  width: 150px;
}
.ds-textarea--width-m {
  width: 230px;
}
.ds-textarea--width-l {
  width: 310px;
}
.ds-textarea--width-xl {
  width: 310px;
}
.ds-textarea--text-monospace {
  font-family: "SourceCodePro", "Courier New", Courier, monospace;
}
.ds-textarea--text-uppercase {
  text-transform: uppercase;
}
.ds-textarea--weight-light {
  border-radius: 0;
  box-shadow: none;
}
.ds-tip {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  font-family: "ProximaNova", sans-serif;
  font-size: 12px;
  line-height: 16px;
  display: block;
  color: #848484;
}
.ds-tip:before,
.ds-tip:after {
  box-sizing: border-box;
}
.ds-tip--style-chameleon {
  color: #b3b3b3;
}
.ds-tip--style-error {
  color: #ff3b30;
}
.ds-tip--size-xs {
  font-size: 10px;
  line-height: 16px;
}
.ds-tooltip {
  --tooltipOffset: 10px;
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "ProximaNova", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  text-indent: 0;
  opacity: 0;
  visibility: hidden;
  display: block;
  min-height: 24px;
  padding: 5px 10px 3px;
  border-radius: 2px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  background: rgba(4, 4, 4, 0.9);
  color: #b3b3b3;
  white-space: nowrap;
  pointer-events: none;
}
.ds-tooltip:before,
.ds-tooltip:after {
  box-sizing: border-box;
}
[attr-show-node="ds-tooltip"]:hover .ds-tooltip {
  opacity: 1;
  visibility: visible;
}
[attr-show-node="ds-tooltip"]:hover .ds-tooltip--delayed {
  transition-delay: 200ms;
}
.ds-tooltip__arrow {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  height: 6px;
  position: fixed;
  border: 3px solid transparent;
}
.ds-tooltip__arrow:before,
.ds-tooltip__arrow:after {
  box-sizing: border-box;
}
.ds-tooltip--visible {
  opacity: 1;
  visibility: visible;
}
.ds-tooltip--is-clickable {
  pointer-events: auto;
}
.ds-tooltip--is-clickable:before {
  content: '';
  display: block;
  position: absolute;
}
.ds-tooltip--is-clickable-top:before,
.ds-tooltip--is-clickable-bottom:before {
  height: calc(var(--tooltipOffset) + 5px);
  right: 0;
  left: 0;
}
.ds-tooltip--is-clickable-top:before {
  bottom: calc((var(--tooltipOffset) + 5px) * -1);
}
.ds-tooltip--is-clickable-bottom:before {
  top: calc((var(--tooltipOffset) + 5px) * -1);
}
.ds-tooltip--is-clickable-right:before,
.ds-tooltip--is-clickable-left:before {
  width: calc(var(--tooltipOffset) + 5px);
  top: 0;
  bottom: 0;
}
.ds-tooltip--is-clickable-right:before {
  left: calc((var(--tooltipOffset) + 5px) * -1);
}
.ds-tooltip--is-clickable-left:before {
  right: calc((var(--tooltipOffset) + 5px) * -1);
}
.ds-tooltip--bottom,
.ds-tooltip--top {
  left: 50%;
  transform: translateX(-50%);
}
.ds-tooltip--bottom .ds-tooltip__arrow,
.ds-tooltip--top .ds-tooltip__arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ds-tooltip--top {
  top: -10px;
  transform: translateX(-50%) translateY(-100%);
}
.ds-tooltip--top .ds-tooltip__arrow {
  border-top-color: rgba(4, 4, 4, 0.9);
  bottom: -6px;
}
.ds-tooltip--bottom {
  top: calc(100% + 10px);
}
.ds-tooltip--bottom .ds-tooltip__arrow {
  border-bottom-color: rgba(4, 4, 4, 0.9);
  top: -6px;
}
.ds-tooltip--right,
.ds-tooltip--left {
  top: 50%;
}
.ds-tooltip--right .ds-tooltip__arrow,
.ds-tooltip--left .ds-tooltip__arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ds-tooltip--right {
  left: calc(100% + 10px);
  transform: translateY(-50%);
}
.ds-tooltip--right .ds-tooltip__arrow {
  border-right-color: rgba(4, 4, 4, 0.9);
  left: -6px;
}
.ds-tooltip--left {
  left: -10px;
  transform: translateX(-100%) translateY(-50%);
}
.ds-tooltip--left .ds-tooltip__arrow {
  border-left-color: rgba(4, 4, 4, 0.9);
  right: -6px;
}
.ds-fieldset {
  display: grid;
}
.ds-fieldset--direction-vertical {
  grid-template-areas: "label" "fields" "tip";
  grid-template-rows: max-content max-content max-content;
}
.ds-fieldset--direction-horizontal {
  column-gap: 6px;
  grid-template-areas: "label fields" "label tip";
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;
}
.ds-fieldset--width-xxs {
  width: 30px;
}
.ds-fieldset--width-xs {
  width: 70px;
}
.ds-fieldset--width-s {
  width: 150px;
}
.ds-fieldset--width-m {
  width: 230px;
}
.ds-fieldset--width-l {
  width: 310px;
}
.ds-fieldset--width-xl {
  width: 310px;
}
.ds-fieldset--width-stretched {
  width: 100%;
}
.ds-fieldset__label {
  margin-bottom: 1px;
  grid-area: label;
}
.ds-fieldset__fields {
  display: flex;
  grid-area: fields;
}
.ds-fieldset__fields--direction-vertical {
  flex-direction: column;
}
.ds-fieldset__fields--direction-horizontal {
  flex-direction: row;
}
.ds-fieldset__fields--space-horizontal-s {
  gap: 6px;
}
.ds-fieldset__fields--space-horizontal-m {
  gap: 10px;
}
.ds-fieldset__fields--space-horizontal-l {
  gap: 16px;
}
.ds-fieldset__fields--space-horizontal-xl {
  gap: 20px;
}
.ds-fieldset__fields--space-vertical-s {
  gap: 6px;
}
.ds-fieldset__fields--space-vertical-m {
  gap: 10px;
}
.ds-fieldset__fields--space-vertical-l {
  gap: 16px;
}
.ds-fieldset__fields--space-vertical-xl {
  gap: 20px;
}
.ds-fieldset__tip {
  margin-top: 2px;
  grid-area: tip;
}
.ds-toast {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 0;
  display: flex;
  width: 464px;
  padding: 16px 24px;
  position: fixed;
  bottom: var(--offset-bottom);
  left: var(--offset-left);
  z-index: 2147483247;
  flex-direction: column;
  background-color: #1e1e1e;
  color: #f3f3f3;
}
.ds-toast:before,
.ds-toast:after {
  box-sizing: border-box;
}
.ds-toast__close {
  position: absolute;
  top: 6px;
  right: 6px;
}
.ds-toast__header {
  font-size: 14px;
  color: #373737;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  color: #f3f3f3;
}
.ds-toast__content {
  padding-top: 16px;
  font-size: 12px;
}
.ds-warning-box {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  text-indent: 0;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 10px 10px 34px;
  position: relative;
  background: #fff9e5;
  color: #806000;
}
.ds-warning-box:before,
.ds-warning-box:after {
  box-sizing: border-box;
}
.ds-warning-box::before {
  color: currentColor;
  position: absolute;
  top: 10px;
  left: 10px;
}
.ds-util-edit-mode:not(.ds-util-is-active),
.ds-util-edit-mode *:not(.ds-util-is-active) {
  -webkit-user-select: initial !important;
  user-select: initial !important;
  pointer-events: none !important;
}
.ds-util-edit-mode .ds-util-edit-mode--allow-click,
.ds-util-edit-mode .ds-util-is-active,
.ds-util-edit-mode .ds-util-is-active * {
  pointer-events: auto !important;
}
.ds-headline-1 {
  font-size: 42px;
  color: #373737;
  font-weight: 800;
  line-height: 50px;
}
.ds-headline-2 {
  font-size: 36px;
  color: #373737;
  font-weight: 800;
  line-height: 40px;
}
.ds-headline-3 {
  font-size: 30px;
  color: #006cff;
  font-weight: 300;
  line-height: 36px;
}
.ds-headline-4 {
  font-size: 24px;
  color: #373737;
  font-weight: bold;
  line-height: 32px;
}
.ds-headline-5 {
  font-size: 20px;
  color: #373737;
  font-weight: normal;
  line-height: 28px;
}
.ds-headline-6 {
  font-size: 18px;
  color: #373737;
  font-weight: normal;
  line-height: 24px;
}
.ds-headline-7 {
  font-size: 16px;
  color: #373737;
  font-weight: normal;
  line-height: 20px;
}
.ds-headline-8 {
  font-size: 14px;
  color: #373737;
  font-weight: normal;
  line-height: 18px;
}
.ds-headline-9 {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.ds-headline-10 {
  font-size: 10px;
  color: #515151;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.ds-util-is-clickable {
  cursor: pointer;
}
.ds-util-text-ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@font-face {
  font-family: 'uxpds-icons';
  src: url('/public/fonts/uxpds-icons-1724836582.woff?v=1724836582') format('woff');
  font-style: normal;
  font-weight: normal;
}
/* Core: icons */
/* czerwone */
/* Raster icons sprite */
.icon-raster-sketch:before {
  content: '';
  height: 11px;
  left: -15px;
  width: 12px;
  top: 3px;
  background: transparent url('/pics/sprite-raster.png') no-repeat;
  background-position: 0 0;
}
.icon-raster-photoshop-cc:before {
  content: '';
  left: -15px;
  height: 12px;
  top: 2px;
  width: 12px;
  background: transparent url('/pics/sprite-raster.png') no-repeat;
  background-position: -20px 0;
}
[class^="icon-general-"].extra-icon:after,
[class*=" icon-general-"].extra-icon:after,
[class^="icon-general-"]:before,
[class*=" icon-general-"]:before {
  text-align: center;
  font-family: 'uxpds-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="ds-icon--"].only-icon-font,
[class*=" ds-icon--"].only-icon-font,
[class^="icon-general-"].only-icon-font,
[class*=" icon-general-"].only-icon-font,
[class^="icon-font-"].only-icon-font,
[class*=" icon-font-"].only-icon-font,
[class^="icon-custom-"].only-icon-font,
[class*=" icon-custom-"].only-icon-font {
  height: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  text-indent: -5000px;
  white-space: nowrap;
  box-sizing: border-box;
  color: transparent;
  font-size: 0 !important;
}
[class^="ds-icon--"].only-icon-font:before,
[class*=" ds-icon--"].only-icon-font:before,
[class^="icon-general-"].only-icon-font:before,
[class*=" icon-general-"].only-icon-font:before,
[class^="icon-font-"].only-icon-font:before,
[class*=" icon-font-"].only-icon-font:before,
[class^="icon-custom-"].only-icon-font:before,
[class*=" icon-custom-"].only-icon-font:before {
  display: block;
  width: 100%;
  margin: 0;
  position: relative;
  text-indent: 0;
  text-align: center;
}
[class^="ds-icon--"].dark-icon:before,
[class*=" ds-icon--"].dark-icon:before,
[class^="icon-general-"].dark-icon:before,
[class*=" icon-general-"].dark-icon:before,
[class^="icon-font-"].dark-icon:before,
[class*=" icon-font-"].dark-icon:before,
[class^="icon-custom-"].dark-icon:before,
[class*=" icon-custom-"].dark-icon:before {
  color: rgba(0, 0, 0, 0.5) !important;
}
label[class^="ds-icon--"],
label[class*=" ds-icon--"],
label[class^="icon-general-"],
label[class*=" icon-general-"],
label[class^="icon-font-"],
label[class*=" icon-font-"],
label[class^="icon-custom-"],
label[class*=" icon-custom-"] {
  margin-bottom: 0;
}
/* CORE: reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
*:before,
*:after {
  box-sizing: border-box;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
img,
fieldset {
  border: none;
}
a,
object,
input:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
li {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: bold;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary,
video,
img,
object,
iframe {
  display: block;
}
/* CORE: fonts */
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: url('/public/fonts/ProximaNova-RegularWeb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: italic;
  font-weight: normal;
  src: url('/public/fonts/ProximaNova-RegularItWeb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: url('/public/fonts/ProximaNova-LightWeb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  src: url('/public/fonts/ProximaNova-SemiboldWeb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: italic;
  font-weight: bold;
  src: url('/public/fonts/ProximaNova-SemiboldItWeb.woff') format('woff');
}
html {
  height: 100%;
  position: relative;
  background: #fff;
  overflow: auto;
}
body {
  min-height: 100%;
  color: #38383b;
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  line-height: 18px;
  overflow-x: hidden;
}
a {
  cursor: pointer;
}
h1 {
  color: inherit;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: auto;
}
h2 {
  color: inherit;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: auto;
}
h3 {
  color: #b3bcc6;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: auto;
}
h4 {
  color: #34495e;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  line-height: auto;
}
h5 {
  color: #c5ccd4;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
  line-height: auto;
}
p,
ul,
ol,
address {
  margin-bottom: 11px;
}
p + ul,
p + ol {
  margin-top: -11px;
}
ol {
  padding-left: 22px;
}
ol ol,
ol ul,
ol nav,
ul ol,
ul ul,
ul nav,
nav ol,
nav ul,
nav nav {
  margin-bottom: 0;
}
ol li {
  list-style: decimal;
}
address {
  font-style: italic;
}
td,
th {
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  line-height: 18px;
}
::selection {
  background: #3498db;
  color: #fff;
}
/* ELEMENTS: link styles */
a {
  position: relative;
  color: #34495e;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  line-height: 14px;
}
a:hover {
  text-decoration: none;
}
a.blue-link {
  color: #3498db;
}
a.new-blue-link {
  font-weight: normal;
  color: #006cff;
}
a.light-link {
  color: #e9eef4 !important;
}
a.blue-gray-link {
  color: #707c8a;
  text-decoration: none;
}
a.blue-gray-link:hover {
  color: #c2c8ce;
}
a.dark-yellow-link {
  color: #b88200;
  text-decoration: none;
  text-transform: uppercase;
}
a.dark-yellow-link:hover {
  color: #58502d;
}
a.light-weight-link {
  font-weight: normal;
}
a.inactive {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
#login {
  display: flex;
  height: 100%;
  background: #fff;
  color: #414141;
  font-size: 14px;
  text-align: center;
  flex-flow: column;
}
#login-login {
  width: 100%;
  max-width: 290px;
  height: 40px;
  padding: 0px 12px;
  border: 1px solid #e6e6e6;
  font-family: Soleil, sans-serif;
  font-size: 14px;
}
#login .uxpin-logo {
  width: 50px;
  height: 50px;
  position: relative;
  text-decoration: none;
}
#login .uxpin-logo:before {
  content: '\E087';
  width: 50px;
  height: 50px;
  border: 1px solid #2b2b2b;
  background-color: #2b2b2b;
  color: #fff;
  font-size: 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desktop-app#login .uxpin-logo {
  pointer-events: none;
}
#login .login-promo {
  display: flex;
  width: 500px;
  max-height: 100vh;
  margin-left: auto;
  background-color: #2b2b2b;
  align-items: flex-end;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  #login .login-promo {
    width: 400px;
  }
}
#login .login-promo .heading {
  display: flex;
  width: 100%;
  padding-top: 36px;
  padding-bottom: 12px;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
}
#login .login-promo .title {
  font-size: 24px;
  color: #373737;
  font-weight: bold;
  line-height: 32px;
  color: #fff;
  font-weight: 300;
  text-transform: none;
}
#login .login-promo .ds-btn--download-app {
  margin-top: 36px;
}
#login .login-promo .pic {
  max-width: 456px;
  width: 100%;
  height: auto;
  margin-top: auto;
  flex-shrink: 0;
  pointer-events: none;
}
@media screen and (max-width: 1366px) {
  #login .login-promo .pic {
    width: calc(100% - 10px);
    margin-left: 10px;
  }
}
#login .login-promo .ds-icon--font__brand-apple:before {
  margin-top: -4px;
}
#login #login-form .static-width {
  width: 450px;
}
#login #login-form .reset-password {
  width: 300px;
}
#login #login-form.with-google-signin {
  text-align: center;
  margin: auto;
}
#login #login-form.with-google-signin .static-width {
  max-width: 300px;
}
#login #login-form.with-google-signin .static-width .ds-input20__field {
  max-width: 320px;
  height: 50px;
}
#login #login-form.with-google-signin label {
  margin-bottom: 9px;
}
#login #login-form.with-google-signin .separator {
  font-size: 14px;
  line-height: 21px;
  color: #b3b3b3;
  width: 100%;
  max-width: 450px;
  height: 21px;
  margin: 17px auto 16px;
  border: none;
  position: relative;
  text-align: center;
}
#login #login-form.with-google-signin .separator:before {
  content: '';
  width: 100%;
  border: 1px #f3f3f3;
  border-style: solid none none;
  position: absolute;
  top: 50%;
  left: 0;
}
#login #login-form.with-google-signin .separator:after {
  content: attr(data-text);
  display: inline-block;
  background-color: #fff;
  padding: 0 10px;
  position: relative;
}
#login #login-form.with-google-signin .google-login-button {
  font-family: Roboto, 'proxima-nova', 'Proxima Nova', sans-serif;
  font-size: 16px;
}
#login #login-form.with-google-signin .google-login-button img {
  margin-right: 10px;
}
#login #login-form.with-google-signin .google-error-msg {
  display: inline-block;
  margin-top: 11px;
}
#login #login-form.with-google-signin .google-error-msg,
#login #login-form.with-google-signin .google-error-msg a {
  color: #ff3b30;
  font-weight: normal;
}
#login #login-form .field-group {
  margin-bottom: 21px;
}
#login #login-form .field-group.without-margin {
  margin-bottom: 0;
}
#login #login-form .form-footer {
  font-size: 16px;
  line-height: 24px;
  color: #6a6a6a;
}
#login #login-form .server-error-msg,
#login #login-form .error-msg {
  display: none;
  margin-top: 9px;
  color: #fb0d00;
}
#login #login-form .server-error-msg.is-visible,
#login #login-form .error-msg.is-visible {
  display: block;
}
#login .footer {
  display: flex;
  position: absolute;
  right: 30px;
  bottom: 30px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
#login .footer .dot-separator {
  width: 4px;
  height: 4px;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #b3b3b3;
}
#login .footer .footer-item {
  margin-bottom: 0;
}
#login .footer .uxpin-logo {
  margin-left: 20px;
}
#login #login-notification {
  width: 450px;
}
#login #login-form,
#login #login-notification {
  text-align: left;
}
.desktop-app#login #login-form,
.desktop-app#login #login-notification {
  -webkit-app-region: no-drag;
}
#login #login-form .form-lead .title,
#login #login-notification .form-lead .title {
  font-size: 36px;
  color: #373737;
  font-weight: 800;
  line-height: 40px;
  margin-bottom: 34px;
  text-transform: none;
}
#login #login-form .form-lead p,
#login #login-notification .form-lead p {
  margin-bottom: 34px;
  color: #909090;
  line-height: 18px;
}
#login #login-form .form-lead p.global-error,
#login #login-notification .form-lead p.global-error {
  color: #e50000;
}
#login #login-form .form-lead p.text,
#login #login-notification .form-lead p.text {
  font-size: 14px;
  line-height: 21px;
  color: #515151;
}
#login #login-form .form-lead p.warning,
#login #login-notification .form-lead p.warning {
  margin-bottom: 22px;
  color: #e50000;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
}
#login #login-form .btns-group .light-blue-style,
#login #login-notification .btns-group .light-blue-style {
  background-color: #006cff;
}
#login #login-form .btns-group .light-blue-style:hover,
#login #login-notification .btns-group .light-blue-style:hover {
  background-color: #3389ff;
}
#login #login-form footer,
#login #login-notification footer {
  padding: 9px 0 0 0;
}
#login #login-form footer:after,
#login #login-notification footer:after {
  display: block;
  height: 0;
  clear: both;
  content: '';
  overflow: hidden;
}
#login #login-form footer .field-group,
#login #login-notification footer .field-group {
  padding: 4px 29px 4px 0;
  border-right: 1px solid #e7e7e7;
  float: left;
}
#login #login-form footer .field-group #loginform_button1:not(.working):hover,
#login #login-notification footer .field-group #loginform_button1:not(.working):hover {
  background-color: #3389ff;
}
#login #login-form footer .field-group #loginform_button1.working:after,
#login #login-notification footer .field-group #loginform_button1.working:after {
  margin-top: -11px;
  border-color: #fff;
}
#login #login-form footer .other-links,
#login #login-notification footer .other-links {
  padding: 12px 0 0 29px;
  float: left;
}
#login #login-form footer .other-links p,
#login #login-notification footer .other-links p {
  display: block;
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
  color: #6a6a6a;
  line-height: 12px;
}
#login #login-form footer .other-links p a,
#login #login-notification footer .other-links p a {
  color: #848484;
  font-weight: normal;
  text-decoration: none;
}
#login #login-form footer .other-links p a:after,
#login #login-notification footer .other-links p a:after {
  bottom: 1px;
}
#login #login-form.password-reminder,
#login #login-notification.password-reminder {
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translateY(75%);
}
#login #login-form.password-reminder footer .other-links,
#login #login-notification.password-reminder footer .other-links {
  padding-top: 24px;
}
#login #login-form.password-reminder.email-sent header p,
#login #login-notification.password-reminder.email-sent header p {
  display: none;
}
#login #login-form.password-reminder.email-sent .success-layer,
#login #login-notification.password-reminder.email-sent .success-layer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#login #login-form.password-reminder.email-sent .success-layer p,
#login #login-notification.password-reminder.email-sent .success-layer p {
  margin-bottom: 30px;
}
#login #login-form.password-reminder.email-sent .success-layer .btn-flat,
#login #login-notification.password-reminder.email-sent .success-layer .btn-flat {
  display: block;
  width: 80px;
  background-color: #006cff;
  line-height: 50px;
  text-decoration: none;
}
#login #login-form.password-reminder.email-sent .success-layer .btn-flat:hover,
#login #login-notification.password-reminder.email-sent .success-layer .btn-flat:hover {
  background-color: #3389ff;
}
#login #login-form.password-reminder.email-sent fieldset,
#login #login-notification.password-reminder.email-sent fieldset {
  display: none;
}
#login #login-form .global-error,
#login #login-notification .global-error {
  color: #ff3b30;
}
#login.desktop-app {
  -webkit-app-region: drag;
}
#login.desktop-app * {
  -webkit-app-region: no-drag;
}
#login.desktop-app #login-form .form-lead .sign-up-notice {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 44px;
  color: #919191;
  text-align: center;
}
#login.desktop-app #login-form .form-lead .signup-link:after {
  border-color: #919191;
}
#login.desktop-app #login-form .form-lead .lonely-dot.invisible {
  opacity: 0;
}
#login .social-login__header-notification {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#login .social-login__message--success {
  text-align: center;
}
#login .social-login__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 1.25rem;
}
#login .social-login__header-right {
  display: flex;
  gap: 0.5em;
}
#login .social-login__header-link {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #6666ff;
  font-weight: 500;
}
#login .social-login__header-separator {
  color: #acacac;
  font-size: 14px;
}
#login .social-login__form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
#login .social-login {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
#login .social-login__title {
  font-family: Soleil, sans-serif;
  font-size: 36px;
  color: #121212;
  text-align: center;
  text-transform: none;
  font-weight: 500;
  margin-bottom: 40px;
}
#login .social-login__form-wrapper {
  padding: 1.25rem;
}
#login form .social-login__label {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #121212;
  display: block;
  margin-bottom: 4px;
}
#login .social-login__input {
  width: 100%;
  max-width: 360px;
  height: 40px;
  padding: 0px 12px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #121212;
  margin-bottom: 12px;
}
#login .social-login__input-group--password {
  position: relative;
}
#login .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 21px;
  transform: translateY(-75%);
  cursor: pointer;
}
#login .social-login__btn {
  width: 100%;
  max-width: 360px;
  font-weight: 600;
  height: 48px;
  padding: 14px 1px 14px 0px;
  border: 2px solid #121212;
  border-radius: 4px;
  background-color: #ccccff;
  font-family: Soleil, sans-serif;
  font-size: 16px;
  color: #121212;
  text-align: center;
}
#login .social-login__btn:hover {
  background-color: #f3f3f3;
}
#login .social-login__separator {
  position: relative;
  text-align: center;
  border-top: 1px solid #e6e6e6;
  margin: 30px 0;
}
#login .social-login__separator span {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0 10px;
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #acacac;
}
#login .social-login__fieldset-btns {
  display: flex;
  gap: 0.5em;
}
#login .social-login__btn--google,
#login .social-login__btn--github,
#login .social-login__btn--sso {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  max-width: 114px;
  height: 48px;
  padding: 0.5em 1.1em;
  border: 2px solid #121212;
  border-radius: 4px;
  font-family: Soleil, sans-serif;
  font-size: 16px;
  color: #121212;
  text-decoration: none;
}
#login .social-login__btn--google:hover,
#login .social-login__btn--github:hover,
#login .social-login__btn--sso:hover {
  background-color: #f3f3f3;
}
#login .social-login__error-msg--google,
#login .social-login__error-msg--github,
#login .social-login__error-msg--server {
  margin-top: 1rem;
  text-align: center;
}
#login .social-login__btn--sso {
  width: 114px;
}
#login .social-login__footer {
  margin-top: 36px;
  text-align: center;
}
#login .social-login__footer-text {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #121212;
}
#login .social-login__footer-link {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #6666ff;
  font-weight: 500;
}
#login .social-login__error-msg {
  color: red;
}
#browser-bar {
  width: 100%;
  padding: 10px 10px 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: #ffd800;
  color: #51687F;
}
::selection {
  background: #006cff;
}
.tfa__error-tip,
.tfa__success-tip {
  display: none;
  color: red;
  font-family: Soleil, sans-serif;
  font-size: 14px;
  margin: 10px 0;
}
.visible {
  display: block;
}
.tfa__verify-code {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #283842;
}
.tfa__logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}
.tfa__flex-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.tfa__form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tfa__h1 {
  font-family: Soleil, sans-serif;
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  text-transform: none;
  margin-bottom: 24px;
}
.tfa__page-description {
  font-family: Soleil, sans-serif;
  font-size: 16px;
  color: #acacac;
}
.tfa__trusted-label {
  margin: 20px 0;
}
.tfa__page-description--alt {
  font-size: 14px;
  margin-left: 4px;
}
.tfa__input-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tfa__input-label {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #fff;
  align-self: start;
}
.tfa__input-field {
  width: 100%;
  max-width: 360px;
  margin-top: 8px;
  height: 40px;
  padding: 0px 12px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #fff;
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #121212;
}
.tfa__btn-flat {
  width: 100%;
  max-width: 360px;
  height: 48px;
  padding: 14px 1px 14px 0px;
  border: 2px solid #121212;
  border-radius: 4px;
  background-color: #ccccff;
  font-family: Soleil, sans-serif;
  font-size: 16px;
  color: #121212;
  text-align: center;
  font-weight: 600;
}
.tfa__btn-flat:hover {
  background-color: #f3f3f3;
}
.tfa__action-resend {
  margin-top: 40px;
}
.tfa__resend-link,
.tfa__underlined-link {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #ccccff;
  font-weight: 500;
}
.tfa__footer-text {
  font-family: Soleil, sans-serif;
  font-size: 14px;
  color: #fff;
}
.tfa__additional-links {
  padding: 20px;
  background-color: #283842;
  text-align: center;
}
form * {
  -webkit-app-region: no-drag;
}
